import { IconProps } from "./iconProps";
import { Colours } from "../Colours";

export const HomeIcon = ({ color, size }: IconProps) => {
  return (
    <svg
      width={size || "25"}
      height={size || "25"}
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M14.1263 1.45543C13.6872 1.11386 13.4676 0.943077 13.2251 0.877428C13.0112 0.819503 12.7857 0.819503 12.5718 0.877428C12.3293 0.943077 12.1097 1.11386 11.6706 1.45543L3.19268 8.04933C2.62596 8.49011 2.34261 8.7105 2.13847 8.9865C1.95764 9.23098 1.82294 9.50641 1.74097 9.79924C1.64844 10.1298 1.64844 10.4888 1.64844 11.2067V20.2504C1.64844 21.6505 1.64844 22.3506 1.92092 22.8854C2.1606 23.3558 2.54306 23.7382 3.01346 23.9779C3.54824 24.2504 4.24831 24.2504 5.64844 24.2504H8.14844C8.49847 24.2504 8.67349 24.2504 8.80718 24.1823C8.92478 24.1224 9.0204 24.0268 9.08032 23.9092C9.14844 23.7755 9.14844 23.6004 9.14844 23.2504V15.0004C9.14844 14.3003 9.14844 13.9503 9.28468 13.6829C9.40452 13.4477 9.59575 13.2565 9.83095 13.1366C10.0983 13.0004 10.4484 13.0004 11.1484 13.0004H14.6484C15.3485 13.0004 15.6985 13.0004 15.9659 13.1366C16.2011 13.2565 16.3924 13.4477 16.5122 13.6829C16.6484 13.9503 16.6484 14.3003 16.6484 15.0004V23.2504C16.6484 23.6004 16.6484 23.7755 16.7166 23.9092C16.7765 24.0268 16.8721 24.1224 16.9897 24.1823C17.1234 24.2504 17.2984 24.2504 17.6484 24.2504H20.1484C21.5486 24.2504 22.2486 24.2504 22.7834 23.9779C23.2538 23.7382 23.6363 23.3558 23.876 22.8854C24.1484 22.3506 24.1484 21.6505 24.1484 20.2504V11.2067C24.1484 10.4888 24.1484 10.1298 24.0559 9.79924C23.9739 9.50641 23.8392 9.23098 23.6584 8.9865C23.4543 8.7105 23.1709 8.49011 22.6042 8.04933L14.1263 1.45543Z"
        fill={color || Colours.lightBlue1}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
