import { useEffect, useState } from "react";
import { API } from "../../utils/API";
import { Space, Table, TableProps } from "antd";
import { Link } from "react-router-dom";

interface DataType {
  id: string;
  number: number;
}

export const useMessage = () => {
  const [reportType, setReportType] = useState<string>("user");
  const [loading, setLoading] = useState<boolean>(false);
  const [postReports, setPostReports] = useState<DataType[]>();
  const [userReports, setUserReports] = useState<DataType[]>();

  useEffect(() => {
    setLoading(true);
    API.getReportNumbers().then((result) => {
      setPostReports(
        result.postReports.map((r) => ({ id: r.postId, number: r.number })),
      );
      setUserReports(
        result.userReports.map((r) => ({ id: r.userId, number: r.number })),
      );
      setLoading(false);
    });
  }, []);

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    Table.EXPAND_COLUMN,
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Links",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Link
            to={
              reportType === "user"
                ? `/user?id=${record.id}`
                : `/post?id=${record.id}`
            }
          >
            <p>View</p>
          </Link>
        </Space>
      ),
    },
  ];

  const handleReportType = (value: any) => {
    setReportType(value);
  };

  return {
    userReports,
    postReports,
    reportType,
    columns,
    handleReportType,
    loading,
  };
};
