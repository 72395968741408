import styled from "styled-components";
import { StyledFileInput, StyledInput } from "../../components/Inputs";
import { Colours } from "../../components/Colours";
import { UploadIcon } from "../../components/icons/UploadIcon";
import { useState } from "react";
import { API } from "../../utils/API";
import { toast } from "react-toastify";

export const CreateBusiness = () => {
  const [image, setImage] = useState<File | null>(null);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const onCreate = async () => {
    if (loading) return;
    if (!image || !title || !description) {
      throw new Error("Missing fields");
    }
    setLoading(true);
    try {
      const imageUrl = await API.uploadBusinessFile(image);
      await API.createBusiness({
        title,
        description,
        image: imageUrl,
        email,
        url,
      });

      setImage(null);
      setTitle("");
      setDescription("");
      setEmail("");
      setUrl("");
    } catch {}
    setLoading(false);
  };

  return (
    <MainWrapper>
      <StyledInput
        placeholder="Title"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setTitle(e.target.value);
        }}
        value={title}
      />
      {image && <StyledImage src={URL.createObjectURL(image)} />}
      <BlueBox>
        Upload Image
        <UploadIcon />
        <StyledFileInput
          type="file"
          accept="image/*"
          multiple
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const files = e.target.files;
            if (files) {
              const fileArray = Array.from(files);
              setImage(fileArray[0]);
            }
          }}
        />
      </BlueBox>
      <StyledTextArea
        placeholder="Description"
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setDescription(e.target.value);
        }}
        value={description}
      />
      <StyledInput
        placeholder="Email (optional)"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setEmail(e.target.value);
        }}
        value={email}
      />
      <StyledInput
        placeholder="Url (optional)"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setUrl(e.target.value);
        }}
        value={url}
      />
      <DarkButton
        onClick={async () => {
          try {
            await toast.promise(onCreate(), {
              pending: "Creating benefit...",
              success: "Benefit created!",
              error: "Failed to create benefit",
            });
          } catch {}
        }}
      >
        Create
      </DarkButton>
    </MainWrapper>
  );
};

const StyledImage = styled.img`
  width: 50%;
  aspect-ratio: 1.1;
  object-fit: cover;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const BlueBox = styled.div`
  width: 100%;
  height: 100px;
  background-color: ${Colours.lightBlue4};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  gap: 16px;
  position: relative;
`;

const StyledTextArea = styled.textarea`
  padding: 16px;
  border: 1px solid ${Colours.darkBlue3};
  border-radius: 8px;
  background-color: "white";
  height: 200px;
  font-size: 16px;
`;

const DarkButton = styled.button`
  padding: 12px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${Colours.darkBlue2};
  color: white;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
`;
