import styled from "styled-components";
import { StyledTitle } from "../../components/Text";
import { Card, Select } from "antd";
import { Colours } from "../../components/Colours";
import { useMessage } from "./useReports";
import { InfoTable } from "./Table";

export const Reports = () => {
  const {
    postReports,
    userReports,
    reportType,
    columns,
    handleReportType,
    loading,
  } = useMessage();

  const messageTypeOption = [
    { value: "user", label: "Users" },
    { value: "post", label: "Posts" },
  ];

  return (
    <StyledBackground>
      <div style={{ width: "90%" }}>
        <StyledTitle $fontsize={36}>Admin / Reports</StyledTitle>
      </div>
      <div style={{ width: "90%" }}>
        <StyledSelect
          defaultValue={reportType}
          style={{ width: 120 }}
          value={reportType}
          onChange={handleReportType}
          options={messageTypeOption}
        />
        <StyledCard loading={loading}>
          <InfoTable
            reportType={reportType}
            postReports={postReports}
            userReports={userReports}
            columns={columns}
          />
        </StyledCard>
      </div>
    </StyledBackground>
  );
};

const StyledBackground = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid ${Colours.darkBlue3};
`;

const StyledCard = styled(Card)`
  width: 100%;
  overflow-x: scroll;
  padding: 0;
  .ant-card-body {
    padding: 0;
  }
`;
