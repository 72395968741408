import { useState } from "react";
import { PostCategory } from "../../utils/Categories";
import { API } from "../../utils/API";
import { toast } from "react-toastify";
import { CreatePost } from "../../utils/types";
import phone from "phone";

type Form = {
  name: string;
  id: string;
  shortId: string;
  title: string;
  price: string;
  location: string;
  description: string;
  images: File[];
  category: PostCategory | null;
  link: string;
  phoneNumber: string;
};

const initialState = {
  name: "",
  id: "",
  shortId: "",
  title: "",
  price: "",
  location: "",
  description: "",
  phoneNumber: "",
  images: [],
  category: null,
  link: "",
};

export const useList = () => {
  const [form, setForm] = useState<Form>(initialState);

  const onIdInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setForm({ ...form, id: text });
  };

  const onShortIdInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setForm({ ...form, shortId: text });
  };

  const onTitleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setForm({ ...form, title: text });
  };

  const onPriceInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    if (text.length === 0) {
      setForm({ ...form, price: "" });
      return;
    }
    try {
      const num = Number(text);
      if (num || num === 0) {
        setForm({ ...form, price: text });
      }
    } catch {}
  };

  const onPhoneNumberInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setForm({ ...form, phoneNumber: text });
  };

  const onLocationInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setForm({ ...form, location: text });
  };

  const onDescriptionInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value;
    setForm({ ...form, description: text });
  };

  const onImagesInput = (images: File[]) => {
    setForm({ ...form, images });
  };

  const onCategoryInput = (category: PostCategory | null) => {
    setForm({ ...form, category });
  };

  const getUser = async () => {
    try {
      const number = parseInt(form.shortId);
      if (isNaN(number)) return;
      const { user } = await API.getUserShortId(number);
      setForm({
        ...form,
        name: `${user.firstName} ${user.lastName}`,
        id: user.id,
      });
    } catch {
      toast.error("User not found");
      setForm({ ...form, name: "", id: "" });
    }
  };

  const onSubmit = async () => {
    const {
      id,
      title,
      price,
      location,
      description,
      images,
      category,
      phoneNumber,
    } = form;
    if (
      !id ||
      !title ||
      !price ||
      !description ||
      !images.length ||
      !category ||
      !location
    ) {
      toast.error("Please enter all the fields");
      throw new Error("Missing fields");
    }
    const formattedNumber = phone(phoneNumber, { country: "GB" });
    if (phoneNumber && !formattedNumber.isValid) {
      toast.error("Invalid phone number");
      throw new Error("Invalid phone number");
    }
    const { urls } = await API.uploadPostImages(images);
    const newPost: CreatePost = {
      userId: form.id,
      title,
      price: parseFloat(price),
      description,
      location,
      images: urls,
      category,
      phoneNumber: formattedNumber.phoneNumber
        ? formattedNumber.phoneNumber
        : undefined,
    };
    const { link } = await API.createPost(newPost);
    setForm({ ...initialState, link });
  };

  return {
    form,
    onIdInput,
    onShortIdInput,
    onPhoneNumberInput,
    onTitleInput,
    onPriceInput,
    onLocationInput,
    onDescriptionInput,
    onImagesInput,
    onCategoryInput,
    getUser,
    onSubmit,
  };
};
