import styled from "styled-components";
import { Colours } from "../../components/Colours";
import { Cascader } from "antd";
import { toast } from "react-toastify";
import { DisplayImages } from "../../components/posts/SwipeableImages";
import { getScreenDimensions } from "../../hooks/useScreenDimensions";
import { StyledTopbar, TotalWrapper } from "../../components/Containers";
import { StyledInput } from "../../components/Inputs";
import { StyledText } from "../../components/Text";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useList } from "./useList";
import { CategoryItems, PostCategory } from "../../utils/Categories";
import { useState } from "react";
import { ImageUploader } from "../../components/posts/ImageUploader";

const options: { value: PostCategory | "YARD_SALE"; label: string }[] =
  CategoryItems.map((item) => {
    return {
      value: item.category,
      label: item.label,
    };
  });

options.unshift({
  value: "YARD_SALE",
  label: "Yard Sale",
});

export const List = () => {
  const { width } = getScreenDimensions();
  const [imageEditor, setImageEditor] = useState<boolean>(false);
  const {
    form,
    onShortIdInput,
    onTitleInput,
    onPriceInput,
    onLocationInput,
    onDescriptionInput,
    onImagesInput,
    onCategoryInput,
    getUser,
    onSubmit,
    onPhoneNumberInput,
  } = useList();

  return (
    <TotalWrapper>
      <StyledTopbar>Create Post </StyledTopbar>
      <MainWrapper>
        {form.link && (
          <CopyToClipboard
            text={form.link}
            onCopy={() => {
              toast.success("Link copied to clipboard");
            }}
          >
            <StyledText>PAYMENT LINK: CLICK TO COPY</StyledText>
          </CopyToClipboard>
        )}
        User ID
        <StyledInput
          placeholder="User Id"
          value={form.shortId}
          onChange={onShortIdInput}
          onBlur={getUser}
        />
        {form.name && <div>{`User's name: ${form.name}`}</div>}
        {parseInt(form.shortId) === 1 && (
          <>
            Phone Number
            <StyledInput
              placeholder="Phone Number"
              value={form.phoneNumber}
              onChange={onPhoneNumberInput}
            />
          </>
        )}
        Title
        <StyledInput
          placeholder="Title"
          value={form.title}
          onChange={onTitleInput}
        />
        {form.images.length > 0 && (
          <DisplayImages
            images={form.images.map((image) =>
              typeof image === "string" ? image : URL.createObjectURL(image),
            )}
            id={""}
            onDelete={(index) => {
              const newImages = [...form.images];
              newImages.splice(index, 1);
              onImagesInput(newImages);
            }}
            width={width * 0.9}
            aspectRatio={0.7}
          />
        )}
        <DarkButton onClick={() => setImageEditor(true)}>
          {form.images.length > 0 ? "Update" : "Upload"} images
        </DarkButton>
        <ImageUploader
          name={"file"}
          maxFileSize={10}
          isOpened={imageEditor}
          onSave={(imgs) => {
            onImagesInput(imgs);
            setImageEditor(false); // closing imageEditor
          }}
          onCancel={() => setImageEditor(false)}
          images={form.images}
        />
        Category
        <StyledCascader
          options={options}
          placeholder="Select a Category"
          onChange={(e: (string | number | null)[]) => {
            onCategoryInput(e?.[0] as PostCategory);
          }}
          variant={"borderless"}
          value={form.category ? [form.category] : undefined}
        />
        Price £
        <StyledInput
          placeholder="Price"
          value={form.price}
          onChange={onPriceInput}
        />
        Location
        <StyledInput
          placeholder="Location"
          value={form.location}
          onChange={onLocationInput}
        />
        Description
        <StyledTextArea
          placeholder="Description"
          value={form.description}
          onChange={onDescriptionInput}
        />
        <DarkButton
          onClick={() => {
            toast.promise(onSubmit(), {
              pending: "Uploading post...",
              success: "Post uploaded",
              error: "Failed to upload post",
            });
          }}
        >
          List
        </DarkButton>
      </MainWrapper>
    </TotalWrapper>
  );
};

const StyledCascader = styled(Cascader)`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid ${Colours.darkBlue3};
`;

const DarkButton = styled.button`
  padding: 12px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${Colours.darkBlue2};
  color: white;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
`;

const StyledTextArea = styled.textarea`
  padding: 16px;
  border: 1px solid ${Colours.darkBlue3};
  border-radius: 8px;
  background-color: "white";
  height: 200px;
  font-size: 16px;
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 16px;
  padding-top: 100px;
  padding-bottom: 24px;
`;
