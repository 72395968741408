import { useEffect, useState } from "react";
import { Message } from "../../utils/types";
import { API } from "../../utils/API";
import { TableProps } from "antd";
import { dateFormatter } from "../../utils/common/dateFormatter";

interface DataType {
  message: string;
  subject: string;
  email: string;
  user: string;
  createdAt: string[];
}

export const useMessage = () => {
  const [messages, setMessages] = useState<Message[]>();
  const [messageType, setMessageType] = useState<string>("all");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    API.allMessages(messageType).then((result) => {
      setMessages(result.messages);
      setLoading(false);
    });
  }, [messageType]);

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (data) => {
        return `${data.firstName} ${data?.lastName}`;
      },
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data) => {
        return dateFormatter({ date: data });
      },
    },
  ];

  const handleChange = (select: any) => {
    setMessageType(select);
  };

  return {
    messages,
    messageType,
    columns,
    handleChange,
    loading,
  };
};
