import styled from "styled-components";
import { Colours } from "../../components/Colours";
import { TotalWrapper } from "../../components/Containers";
import { Status, UserSnapshot } from "../../utils/types";
import { useEffect, useState } from "react";
import { API } from "../../utils/API";
import { useQuery } from "../../hooks/useQuery";
import { Avatar } from "../Post/Avatar";
import { Profile } from "../../components/icons/ProfileIcon";
import { StyledText, StyledTitle } from "../../components/Text";
import { DisplayPost } from "../../components/posts/DisplayPost";
import { PeopleIcon } from "../../components/icons/PeopleIcon";
import { PinIcon } from "../../components/icons/PinIcon";
import { getScreenDimensions } from "../../hooks/useScreenDimensions";

import House from "../../assets/icons/House.svg";
import Industry from "../../assets/icons/industry.svg";
import { BackIcon } from "../../components/icons/BackIcon";

const memberSince = (joined: string) => {
  const date = new Date(joined);
  const ms = Date.now() - date.getTime();
  const years = Math.floor(ms / (1000 * 60 * 60 * 24 * 365));
  if (years > 0) {
    return `Member for ${years} year${years > 1 ? "s" : ""}`;
  }
  const months = Math.floor(ms / (1000 * 60 * 60 * 24 * 30));
  if (months > 0) {
    return `Member for ${months} month${months > 1 ? "s" : ""}`;
  }
  const days = Math.floor(ms / (1000 * 60 * 60 * 24));
  if (days > 0) {
    return `Member for ${days} day${days > 1 ? "s" : ""}`;
  } else {
    return `Member for less than a day`;
  }
};

export const ProfilePage = () => {
  const query = useQuery();
  const [user, setUser] = useState<UserSnapshot | null>(null);
  const { width } = getScreenDimensions();

  useEffect(() => {
    window.scrollTo(0, 0);
    const id = query.get("id");
    if (!id) {
      return;
    }
    API.getUser(id).then(async ({ user }) => {
      setUser(user);
    });
  }, [query]);

  if (!user) return <></>;

  const banUser = async () => {
    await API.banUser(user.id);
    window.location.reload();
  };

  const unBanUser = async () => {
    await API.unbanUser(user.id);
    window.location.reload();
  };

  return (
    <TotalWrapper>
      <MainWrapper>
        {user.avatar ? (
          <Avatar image={user.avatar} size={width * 0.5} />
        ) : (
          <Profile size={width * 0.5} />
        )}
        <StyledTitle
          $fontsize={36}
        >{`${user.firstName} ${user.lastName}`}</StyledTitle>
        <StyledText style={{ opacity: 0.5 }}>
          {`${memberSince(user.memberSince)} ShortID: ${user.shortId}`}
        </StyledText>
        <RowView style={{ justifyContent: "space-evenly", width: "90%" }}>
          <RowView>
            <PeopleIcon size={14} />
            <StyledText>{`${user.referals.length} Endorsements`}</StyledText>
          </RowView>
          <RowView>
            <PinIcon size={14} />
            <StyledText>{`Based in ${user.location}`}</StyledText>
          </RowView>
        </RowView>
        <RowView style={{ justifyContent: "space-evenly", width: "90%" }}>
          <RowView>
            <img src={House} alt="House" />
            <StyledText>{user.education}</StyledText>
          </RowView>
          <RowView>
            <img src={Industry} alt="Industry" />
            <StyledText>{user.industry}</StyledText>
          </RowView>
        </RowView>
        <BanUserWrapper>
          {user.status === Status.BANNED ? (
            <StyledText
              $fontsize={16}
              $colour={Colours.white}
              onClick={unBanUser}
            >
              UNBAN USER
            </StyledText>
          ) : (
            <StyledText
              $fontsize={16}
              $colour={Colours.white}
              onClick={banUser}
            >
              BAN USER
            </StyledText>
          )}
        </BanUserWrapper>
        <BackButton
          onClick={() => {
            window.history.back();
          }}
        >
          <BackIcon size={20} color="white" />
        </BackButton>
        <Bar />
        <PostsWrapper>
          {user.posts?.map((post) => <DisplayPost key={post.id} post={post} />)}
        </PostsWrapper>
      </MainWrapper>
    </TotalWrapper>
  );
};

const BackButton = styled.div`
  position: absolute;
  display: flex;
  top: 10px;
  left: 10px;
  background-color: ${Colours.darkBlue1};
  opacity: 0.5;
  padding: 12px 12px;
  border-radius: 50px;
`;

const BanUserWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: ${Colours.red};
  padding: 4px 8px;
  border-radius: 8px;
`;

const PostsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
  padding-top: 20px;
  gap: 20px;
`;

const RowView = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

const Bar = styled.div`
  height: 2px;
  background-color: ${Colours.lightBlue4};
  margin: 0 10px;
`;
