import { Table, TableProps } from "antd";
import React from "react";

interface IDataTable {
  data: TableProps["dataSource"];
  columns: TableProps["columns"];
}

export const DataTable: React.FC<IDataTable> = ({ data, columns }) => {
  return <Table columns={columns} dataSource={data} />;
};
