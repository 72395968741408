import styled from "styled-components";
import { Colours } from "./Colours";

export const StyledTopbar = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  height: 50px;
  flex-direction: row;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  background-color: ${Colours.beige3};
  align-items: flex-end;
  padding: 16px;
  padding-left: 48px;
  z-index: 1;
  gap: 16px;
  font-size: 30px;
`;

export const TotalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Quattrocento Sans;
`;
