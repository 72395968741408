import { Colours } from "../Colours";
import { IconProps } from "./iconProps";

export const UploadIcon = ({ color, size }: IconProps) => {
  return (
    <svg
      width={size || "22"}
      height={size || "20"}
      viewBox="0 0 22 20"
      fill="none"
    >
      <path
        d="M7 14L11 10M11 10L15 14M11 10V19M19 14.7428C20.2215 13.734 21 12.2079 21 10.5C21 7.46243 18.5376 5 15.5 5C15.2815 5 15.0771 4.886 14.9661 4.69774C13.6621 2.48484 11.2544 1 8.5 1C4.35786 1 1 4.35786 1 8.5C1 10.5661 1.83545 12.4371 3.18695 13.7935"
        stroke={Colours.lightBlue1}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
