import { DateTime } from "luxon";

type dateFormatterType = {
  date: string;
  fromFormat?: string;
  toFormat?: string;
};

export const dateFormatter = ({ date, fromFormat, toFormat = 'dd/MM/yyyy' }: dateFormatterType) => {
  let luxonDateTime = fromFormat ? DateTime.fromFormat(date, fromFormat) : DateTime.fromISO(date);
  return luxonDateTime.toFormat(toFormat);
};