import styled from "styled-components";
import { API } from "../../utils/API";
import { useEffect, useState } from "react";
import { BaseUser, PaymentStatus, Post, PostStatus } from "../../utils/types";
import { TopImage } from "./TopImage";
import { StyledText, StyledTitle } from "../../components/Text";
import { PeopleIcon } from "../../components/icons/PeopleIcon";
import { Colours } from "../../components/Colours";
import { Avatar } from "./Avatar";
import { Profile } from "../../components/icons/ProfileIcon";
import { useQuery } from "../../hooks/useQuery";
import { DarkButton, LightButton } from "../../components/Inputs";
import { toast } from "react-toastify";
import { CategoryItem, CategoryItems } from "../../utils/Categories";
import CopyToClipboard from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";

import { Actions } from "./Actions";

const MemberSince = (date: string) => {
  const d = new Date(date);
  const month = d.toLocaleString("default", { month: "short" });
  const year = d.getFullYear();
  return `${month} ${year}`;
};

const PaymentStatusToText = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.Paid:
      return "This post has been paid for";
    case PaymentStatus.Pending:
      return "The user has completed the payment process. We will take the payment when the post is approved";
    case PaymentStatus.None:
      return "The user has not completed the payment process";
  }
};

export const PostPage = () => {
  const query = useQuery();
  const [post, setPost] = useState<Post | null>(null);
  const [user, setUser] = useState<BaseUser | null>(null);
  const navigator = useNavigate();

  const AttemptPaymentCaupture = async () => {
    if (!post) {
      return;
    }
    await API.takePayment(post.id);
    window.location.reload();
  };

  const SkipPayment = async () => {
    if (!post) {
      return;
    }
    await API.skipPayment(post.id);
    window.location.reload();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const id = query.get("id");
    if (!id) {
      return;
    }
    API.getPost(id).then(async ({ post: newPost }) => {
      setPost(newPost);
      const { user: newUser } = await API.getUser(newPost.userId);
      setUser(newUser);
      if (newPost.approved && newPost.paymentStatus === PaymentStatus.Pending) {
        try {
          await API.takePayment(newPost.id);
          window.location.reload();
        } catch (e) {
          console.error(e);
        }
      }
    });
  }, [query]);

  if (!post || !user) {
    return <></>;
  }

  const categoryItem = CategoryItems.find(
    (item) => item.category === post.category,
  ) as CategoryItem;

  const Icon = categoryItem?.icon;

  return (
    <TotalWrapper>
      <TopImage images={post.images} id={post.id} />
      <MainWrapper>
        <RowView style={{ gap: 8 }}>
          <Icon />
          <StyledText $fontsize={16}>{categoryItem.label}</StyledText>
        </RowView>
        <Bar />
        <Actions post={post} />
        <Bar />
        <RowView style={{ justifyContent: "space-between" }}>
          <ColumnView>
            <StyledTitle $fontsize={24}>{post.title}</StyledTitle>
            <StyledText $fontsize={16}>{post.approved}</StyledText>
          </ColumnView>
          <ColumnView>
            <StyledText $fontsize={20}>{`£${post.price / 100}`}</StyledText>
          </ColumnView>
        </RowView>
        <Bar />
        <RowView
          style={{ gap: 8 }}
          onClick={() => {
            navigator(`/user?id=${user.id}`);
          }}
        >
          {user.avatar ? (
            <Avatar size={38} image={user.avatar} />
          ) : (
            <Profile size={38} />
          )}
          <ColumnView style={{ flex: 1 }}>
            <StyledText
              $fontsize={18}
            >{`${user.firstName} ${user.lastName}`}</StyledText>
            <RowView style={{ gap: 8 }}>
              <PeopleIcon />
              <StyledText $fontsize={16}>{user.referals.length}</StyledText>
              <StyledText
                $fontsize={16}
              >{`ShortID: ${user.shortId}`}</StyledText>
            </RowView>
          </ColumnView>
          <ColumnView style={{ alignItems: "flex-end" }}>
            <StyledText $fontsize={16}>Member since</StyledText>
            <StyledText $fontsize={16}>
              {MemberSince(user.memberSince)}
            </StyledText>
          </ColumnView>
        </RowView>
        <Bar />
        <StyledText $fontsize={26}>Description</StyledText>
        <ColumnView style={{ gap: 8 }}>
          {post.description.split("\n").map((line, index) => (
            <StyledText key={index} $fontsize={16}>
              {line}
            </StyledText>
          ))}
        </ColumnView>
        <StyledText $fontsize={18}>{`Price ${post.price / 100}`}</StyledText>
        <Bar />
        <StyledText $fontsize={18}>{`Payment Status: ${PaymentStatusToText(
          post.paymentStatus,
        )}`}</StyledText>
        {post.paymentStatus === PaymentStatus.None &&
          post.approved === PostStatus.Approved && (
            <>
              <StyledText $fontsize={18}>
                Does the user believe they have completed the payment process,
                but it has not registered here?
              </StyledText>
              <DarkButton
                onClick={async () => {
                  await toast.promise(AttemptPaymentCaupture, {
                    pending: "Taking payment...",
                    success: "Payment taken!",
                    error: "Failed to take payment",
                  });
                  window.location.reload();
                }}
              >
                <StyledText $fontsize={18} $colour={Colours.white}>
                  Take Payment
                </StyledText>
              </DarkButton>

              <LightButton
                onClick={async () => {
                  await toast.promise(SkipPayment, {
                    pending: "Skipping payment...",
                    success: "Payment skipped!",
                    error: "Failed to skip payment",
                  });
                  window.location.reload();
                }}
              >
                <StyledText $fontsize={18}>Skip Payment</StyledText>
              </LightButton>
            </>
          )}
        {post.paymentStatus === PaymentStatus.None && post.paymentUrl && (
          <CopyToClipboard
            text={post.paymentUrl}
            onCopy={() => {
              toast.success("Link copied to clipboard");
            }}
          >
            <StyledText>PAYMENT LINK: CLICK TO COPY</StyledText>
          </CopyToClipboard>
        )}
        {post.approved === PostStatus.Pending && (
          <>
            <DarkButton
              onClick={async () => {
                await toast.promise(API.approvePost(post.id), {
                  pending: "Approving post...",
                  success: "Post approved!",
                  error: "Failed to approve post",
                });
                window.location.reload();
              }}
            >
              <StyledText $fontsize={18} $colour={Colours.white}>
                Approve Post
              </StyledText>
            </DarkButton>
            <LightButton
              onClick={async () => {
                await toast.promise(API.rejectPost(post.id), {
                  pending: "Approving post...",
                  success: "Post approved!",
                  error: "Failed to approve post",
                });
                window.location.reload();
              }}
            >
              <StyledText $fontsize={18}>Decline Post</StyledText>
            </LightButton>
          </>
        )}
      </MainWrapper>
    </TotalWrapper>
  );
};

const Bar = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Colours.darkBlue1};
`;

const RowView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ColumnView = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainWrapper = styled.div`
  width: 90%;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
