import { Table } from "antd";
import { Reasons } from "./Reasons";

type Props = {
  reportType: string;
  postReports?: { id: string; number: number }[];
  userReports?: { id: string; number: number }[];
  columns: any;
};

export const InfoTable = ({
  reportType,
  postReports,
  userReports,
  columns,
}: Props) => {
  return (reportType === "post" && postReports) ||
    (reportType === "user" && userReports) ? (
    <Table
      columns={columns}
      rowSelection={{}}
      expandable={{
        expandedRowRender: (record: any) => (
          <Reasons id={record.id} type={reportType} />
        ),
      }}
      dataSource={reportType === "post" ? postReports : userReports}
    />
  ) : (
    <div>No Data.</div>
  );
};
