import { useEffect, useState } from "react";
import { styled } from "styled-components";
import { DarkButton, StyledInput } from "../../components/Inputs";
import { StyledText, StyledTitle } from "../../components/Text";
import { API } from "../../utils/API";
import { toast } from "react-toastify";
import { Colours } from "../../components/Colours";
import { StyledTopbar } from "../../components/Containers";
import { BackIcon } from "../../components/icons/BackIcon";
import { useNavigate } from "react-router-dom";

export const GlobalNotification = () => {
  const [title, setTitle] = useState<string>("");
  const [body, setBody] = useState<string>("");
  const [confirm, setConfirm] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sendNotification = async () => {
    await API.notifyAll(title, body, { dummy: "data" });
    setTitle("");
    setBody("");
  };

  const onSend = async () => {
    try {
      if (!title || !body || !confirm) {
        toast.error("Please fill in all fields and confirm");
        return;
      }
      await toast.promise(sendNotification(), {
        pending: "Sending notification..",
        success: "Notification sent",
        error: "Failed to send notification",
      });
    } catch {}
  };

  return (
    <TotalWrapper>
      <StyledTopbar>
        <div
          onClick={() => {
            navigate("/");
          }}
        >
          <BackIcon />
        </div>
        <StyledTitle $fontsize={32}>Notifications</StyledTitle>
      </StyledTopbar>
      <InnerWrapper>
        <StyledText>Send a notification to all users.</StyledText>
        <StyledInput
          placeholder="Title"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setTitle(e.target.value);
          }}
          value={title}
        />
        <StyledInput
          placeholder="Body"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setBody(e.target.value);
          }}
          value={body}
        />
        <RowView>
          <StyledText>Confirm</StyledText>
          <StyledCircle onClick={() => setConfirm(true)} confirmed={confirm} />
        </RowView>
        <DarkButton onClick={onSend}>
          <StyledText $colour={Colours.white}>Send</StyledText>
        </DarkButton>
      </InnerWrapper>
    </TotalWrapper>
  );
};

const RowView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const StyledCircle = styled.div<{ confirmed: boolean }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid ${Colours.darkBlue1};
  background-color: ${(props) =>
    props.confirmed ? Colours.darkBlue1 : "transparent"};
  margin-top: 10px;
  margin-bottom: 10px;
`;

const InnerWrapper = styled.div`
  width: 90%;
  padding-top: 100px;
  height: 80vh;
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const TotalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
