import styled from "styled-components";
import { Colours } from "./Colours";

export const StyledText = styled.span<{
  $fontsize?: number;
  $colour?: string;
  $bold?: boolean;
}>`
  font-size: ${(props) => props.$fontsize}px;
  font-family: "Jost";
  color: ${(props) => props.$colour || Colours.darkBlue1};
  font-weight: ${(props) => (props.$bold ? "bold" : "normal")};
`;

export const StyledTitle = styled.span<{
  $fontsize?: number;
  $colour?: string;
  $bold?: boolean;
}>`
  font-size: ${(props) => props.$fontsize}px;
  font-family: "Radley";
  color: ${(props) => props.$colour || Colours.darkBlue1};
  font-weight: ${(props) => (props.$bold ? "bold" : "normal")};
`;
