import styled from "styled-components";
import { Colours } from "../../components/Colours";
import { Business } from "../../utils/types";
import { StyledText } from "../../components/Text";
import { DeleteCircleIcon } from "../../components/icons/DeleteCircleIcon";
import { EditIcon } from "../../components/icons/EditIcon";
import { useNavigate } from "react-router-dom";

export const BusinessView = ({
  business,
  onDelete,
}: {
  business: Business;
  onDelete: () => void;
}) => {
  const navigate = useNavigate();
  const onEdit = () => {
    navigate(`/edit-business?id=${business.id}`);
  };

  return (
    <Box>
      <DeleteWrapper onClick={onDelete}>
        <DeleteCircleIcon size={22} color={Colours.red} />
      </DeleteWrapper>
      <EditWrapper onClick={onEdit}>
        <EditIcon size={14} color={Colours.white} />
      </EditWrapper>
      <StyledImage src={business.image} />
      <StyledText $fontsize={18}>{business.title}</StyledText>
      <StyledText $fontsize={14}>{business.description}</StyledText>
      {business.url && (
        <StyledText
          $fontsize={14}
        >{`${business.url} | Unique Clicks: ${business._count?.urlClicks}`}</StyledText>
      )}
      {business.email && (
        <StyledText
          $fontsize={14}
        >{`${business.email} | Unique Clicks: ${business._count?.emailClicks}`}</StyledText>
      )}
    </Box>
  );
};

const EditWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 5px;
  left: 5px;
  width: 28px;
  height: 28px;
  background-color: ${Colours.darkBlue1};
  border-radius: 50%;
  opacity: 0.5;
`;

const DeleteWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const StyledImage = styled.img`
  width: 100%;
  aspect-ratio: 1.1;
  object-fit: cover;
  border-radius: 8px;
`;

const Box = styled.div`
  position: relative;
  background-color: ${Colours.lightBlue4};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  flex: 1;
`;
