import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Home } from "./Home/Home";
import { PostPage } from "./Post/Post";
import styled from "styled-components";
import { Colours } from "../components/Colours";
import { BottomBar } from "./BottomBar";
import { Edit } from "./edit/Edit";
import { Auth } from "../utils/auth";
import { useEffect, useState } from "react";
import { AuthPage } from "./AuthPage";
import { API } from "../utils/API";
import { Posts } from "./Posts/Posts";
import { Users } from "./users/users";
import { Messages } from "./Messages/Messages";
import { ProfilePage } from "./users/User";
import { Directory } from "./directory/Directory";
import { List } from "./list/List";
import { Reports } from "./reports/Reports";
import { EditBusiness } from "./edit-business/EditBusiness";
import { GlobalNotification } from "./Notification/GlobalNotification";

const routes = [
  <Route path="/auth" key="Auth" element={<AuthPage />} />,
  <Route path="/" key="Home" element={<Home />} />,
  <Route path="/posts" key="Posts" element={<Posts />} />,
  <Route path="/list" key="List" element={<List />} />,
  <Route path="/users" key="Users" element={<Users />} />,
  <Route path="/messages" key="Messages" element={<Messages />} />,
  <Route path="/post" key="Post" element={<PostPage />} />,
  <Route path="/edit" key="Edit" element={<Edit />} />,
  <Route path="/user" key="User" element={<ProfilePage />} />,
  <Route path="/directory" key="Directory" element={<Directory />} />,
  <Route path="/edit-business" key="EditBusiness" element={<EditBusiness />} />,
  <Route path="/reports" key="Reports" element={<Reports />} />,
  <Route
    path="/notifications"
    key="Notifications"
    element={<GlobalNotification />}
  />,
];

export const RouteManager = () => {
  const location = useLocation();
  const navigator = useNavigate();
  const auth = Auth.instance;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!auth.getToken()) {
      if (location.pathname !== "/auth") {
        navigator("/auth");
        setLoading(false);
      }
    } else {
      API.verify()
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          auth.clearToken();
          navigator("/auth");
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname === "/auth"]);

  if (loading) {
    return null;
  }

  return (
    <StyledMainWrapper>
      <Routes key={location.pathname} location={location}>
        {routes}
      </Routes>
      <BottomBar />
    </StyledMainWrapper>
  );
};

const StyledMainWrapper = styled.div`
  width: 100vw;
  background-color: ${Colours.beige3};
  padding-bottom: 100px;
`;
