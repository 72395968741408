import { Colours } from "../Colours";
import { IconProps } from "./iconProps";

export const CreatePost = ({ color, size }: IconProps) => {
  return (
    <svg
      width={size || "28"}
      height={size || "28"}
      viewBox="0 0 27 28"
      fill="none"
    >
      <path
        d="M13.3008 9V19M8.30078 14H18.3008M25.8008 14C25.8008 20.9036 20.2043 26.5 13.3008 26.5C6.39722 26.5 0.800781 20.9036 0.800781 14C0.800781 7.09644 6.39722 1.5 13.3008 1.5C20.2043 1.5 25.8008 7.09644 25.8008 14Z"
        stroke={color || Colours.lightBlue1}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
