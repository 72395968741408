import { PostCategory } from "./Categories";

export interface Post {
  title: string;
  description: string;
  images: string[];
  price: number;
  location: string;
  id: string;
  userId: string;
  approved: PostStatus;
  category: PostCategory;
  paymentStatus: PaymentStatus;
  paymentUrl?: string;
}

export enum PaymentStatus {
  None = "NONE",
  Pending = "PENDING",
  Paid = "PAID",
}

export type CreatePost = {
  title: string;
  description: string;
  images: string[];
  price: number;
  location: string;
  userId: string;
  category: PostCategory;
  phoneNumber?: string;
};

export type EditPost = {
  title: string;
  description: string;
  images: string[];
  price: number;
  location: string;
  category: PostCategory;
  id: string;
};

export enum PostStatus {
  Approved = "APPROVED",
  Pending = "PENDING",
  Rejected = "REJECTED",
  Fulfilled = "FULFILLED",
  Paused = "PAUSED",
}

export enum Status {
  ACCEPTED = "ACCEPTED",
  ONBOARDING = "ONBOARDING",
  PENDING = "PENDING",
  BANNED = "BANNED",
}

export interface BaseUser {
  id: string;
  shortId: string;
  firstName: string;
  lastName: string;
  memberSince: string;
  gender: "MALE" | "FEMALE";
  ageRange: string;
  phoneNumber: string;
  location: string;
  avatar?: string;
  industry: string;
  education: string;
  instagram?: string;
  linkedIn?: string;
  referals: string[];
}

export type UserSnapshot = BaseUser & {
  email: string;
  lastName: string;
  referals: BaseUser[];
  referred: BaseUser[];
  followingCategories: PostCategory[];
  pushToken?: string;
  status: Status;
  signInMethod?: "EMAIL" | "GOOGLE" | "APPLE";
  posts: Post[];
};

export type Analytics = {
  users: {
    total: number;
    accepted: number;
    onboarding: number;
    pending: number;
  };
  posts: {
    total: number;
    live: number;
    fulfiled: number;
    pending: number;
    paused: number;
    rejected: number;
  };
};

export type CreateBusiness = {
  title: string;
  description: string;
  image: string;
  url?: string;
  email?: string;
};

export type Business = {
  id: string;
  title: string;
  description: string;
  image: string;
  url?: string;
  email?: string;
  _count?: {
    emailClicks: number;
    urlClicks: number;
  };
};

export type Message = {
  id: string;
  userId: string;
  user: BaseUser;
  email?: string;
  subject: string;
  message: string;
};
