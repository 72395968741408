export enum Colours {
  darkBlue1 = "#233257",
  darkBlue2 = "#35496B",
  darkBlue3 = "#667999",
  lightBlue1 = "#3585BD",
  lightBlue2 = "#74AFD8",
  lightBlue3 = "#AFD1E9",
  lightBlue4 = "#EBF4F9",
  blueGrey = "#6682B3",
  beige1 = "#EBE7E0",
  beige2 = "#F6F4F1",
  beige3 = "#FAF9F3",
  pink1 = "#FFD3D0",
  pink2 = "#FFA099",
  black = "#000000",
  white = "#FFFFFF",
  red = "#C65143",
  green = "#8da48b",
}
