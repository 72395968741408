import styled from "styled-components";
import { StyledTitle } from "../../components/Text";
import { DataTable } from "../../components/dataTable";
import { Card, Select } from "antd";
import { Colours } from "../../components/Colours";
import { useMessage } from "./useMessage";

export const Messages = () => {
  const { messages, messageType, columns, handleChange, loading } =
    useMessage();

  const messageTypeOption = [
    { value: "all", label: "All" },
    { value: "contact-us", label: "Contact us" },
    { value: "bug-report", label: "Bug Report" },
  ];

  return (
    <StyledBackground>
      <div style={{ width: "90%" }}>
        <StyledTitle $fontsize={36}>Admin / Messages</StyledTitle>
      </div>
      <div style={{ width: "90%" }}>
        <StyledSelect
          defaultValue={messageType}
          style={{ width: 120 }}
          value={messageType}
          onChange={handleChange}
          options={messageTypeOption}
        />
        <StyledCard loading={loading}>
          {messages ? (
            <DataTable data={messages} columns={columns} />
          ) : (
            <div>No Data.</div>
          )}
        </StyledCard>
      </div>
    </StyledBackground>
  );
};

const StyledBackground = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid ${Colours.darkBlue3};
`;

const StyledCard = styled(Card)`
  width: 100%;
  overflow-x: scroll;
  padding: 0;
  .ant-card-body {
    padding: 0;
  }
`;
