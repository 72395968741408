import { IconProps } from "../components/icons/iconProps";
import CarImage from "../assets/images/WWR.png";
import { styled } from "styled-components";
import PetsImage from "../assets/images/pets.png";
import TicketImage from "../assets/images/tickets.png";
import PropertyForSaleImage from "../assets/images/PFS.png";
import PropertyForRentImage from "../assets/images/PFR.png";
import RoomToRentImage from "../assets/images/room.png";
import HolidayHomeImage from "../assets/images/HH.png";
import JobsImage from "../assets/images/jobs.png";
import RequestsImage from "../assets/images/requests.png";
import BusinessImage from "../assets/images/business.png";
import BoatsImage from "../assets/images/boats.png";
import YardsaleImage from "../assets/images/yardsale.png";

export enum PostCategory {
  Pets = "PETS",
  Tickets = "TICKETS",
  Cars = "CARS",
  Boats = "BOATS",
  Property_For_Sale = "PROPERTY_FOR_SALE",
  Property_For_Rent = "PROPERTY_FOR_RENT",
  Room_To_Rent = "ROOM_TO_RENT",
  Holiday_Home = "HOLIDAY_HOME",
  Jobs = "JOBS",
  Requests = "REQUESTS",
  Business = "BUSINESS",
  Yardsale = "YARD_SALE",
}

const icon = (path: string) => (props: IconProps) => (
  <StyledImage src={path} alt="icon" {...props} />
);

export type CategoryItem = {
  label: string;
  category: PostCategory;
  icon: (props: IconProps) => JSX.Element;
};

export const CategoryItems: CategoryItem[] = [
  {
    label: "Pets",
    category: PostCategory.Pets,
    icon: icon(PetsImage),
  },
  {
    label: "Tickets",
    category: PostCategory.Tickets,
    icon: icon(TicketImage),
  },
  {
    label: "Cars",
    category: PostCategory.Cars,
    icon: icon(CarImage),
  },
  {
    label: "Property for Sale",
    category: PostCategory.Property_For_Sale,
    icon: icon(PropertyForSaleImage),
  },
  {
    label: "Property for Rent",
    category: PostCategory.Property_For_Rent,
    icon: icon(PropertyForRentImage),
  },
  {
    label: "Room to Rent",
    category: PostCategory.Room_To_Rent,
    icon: icon(RoomToRentImage),
  },
  {
    label: "Holiday Home",
    category: PostCategory.Holiday_Home,
    icon: icon(HolidayHomeImage),
  },
  {
    label: "Jobs",
    category: PostCategory.Jobs,
    icon: icon(JobsImage),
  },
  {
    label: "Boats",
    category: PostCategory.Boats,
    icon: icon(BoatsImage),
  },
  {
    label: "Requests",
    category: PostCategory.Requests,
    icon: icon(RequestsImage),
  },
  {
    label: "Business",
    category: PostCategory.Business,
    icon: icon(BusinessImage),
  },
  {
    label: "Yard Sale",
    category: PostCategory.Yardsale,
    icon: icon(YardsaleImage),
  },
];

const StyledImage = styled.img`
  width: 20%;
  aspect-ratio: 1;
  object-fit: contain;
  border-radius: 8px;
`;
