type Props = {
  image: string;
  size: number;
};

export const Avatar = ({ image, size }: Props) => {
  return (
    <img
      src={image}
      alt="avatar"
      style={{
        width: size,
        height: size,
        borderRadius: size / 2,
      }}
    />
  );
};
