import styled from "styled-components";
import { StyledText, StyledTitle } from "../../components/Text";
import { Colours } from "../../components/Colours";
import { StyledTopbar, TotalWrapper } from "../../components/Containers";
import { useEffect, useMemo, useRef, useState } from "react";
import { CreateBusiness } from "./CreateBusiness";
import { Business } from "../../utils/types";
import { API } from "../../utils/API";
import { BusinessView } from "./Business";

export const Directory = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [directory, setDirectory] = useState<Business[]>([]);
  const loading = useRef<boolean | "END">(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    API.getDirectory().then((res: Business[]) => {
      setDirectory(res);
    });
  }, []);

  const businessPairs = useMemo(() => {
    const pairs = [];
    for (let i = 0; i < directory.length; i += 2) {
      if (i + 1 >= directory.length) {
        pairs.push(
          <BusinessWrapper style={{ width: "50%" }}>
            <BusinessView
              business={directory[i]}
              onDelete={async () => {
                await API.deleteBusiness(directory[i].id);
                const newBenefits = [...directory];
                newBenefits.splice(i, 1);
                setDirectory(newBenefits);
              }}
              key={directory[i].id}
            />
          </BusinessWrapper>,
        );
        break;
      }
      pairs.push(
        <BusinessWrapper>
          <BusinessView
            business={directory[i]}
            onDelete={async () => {
              await API.deleteBusiness(directory[i].id);
              const newBenefits = [...directory];
              newBenefits.splice(i, 1);
              setDirectory(newBenefits);
            }}
            key={directory[i].id}
          />
          <BusinessView
            business={directory[i + 1]}
            onDelete={async () => {
              await API.deleteBusiness(directory[i + 1].id);
              const newBenefits = [...directory];
              newBenefits.splice(i + 1, 1);
              setDirectory(newBenefits);
            }}
            key={directory[i + 1].id}
          />
        </BusinessWrapper>,
      );
    }
    return pairs;
  }, [directory]);

  useEffect(() => {
    const handleScroll = async () => {
      const totalScrollHeight = document.documentElement.scrollHeight;
      const currentScrollPosition = window.scrollY + window.innerHeight;

      if (currentScrollPosition >= totalScrollHeight - 400) {
        if (!loading.current) {
          loading.current = true;
          const cursor = directory[directory.length - 1].id;
          const newBusinesses = await API.getDirectory(cursor);
          if (newBusinesses.length === 0) {
            loading.current = "END";
          }
          const ids = directory?.map((p) => p.id) || [];
          const filteredBusinesses = newBusinesses.filter(
            (p) => !ids.includes(p.id),
          );
          setDirectory([...(directory || []), ...filteredBusinesses]);
          loading.current = false;
        }
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [directory]);

  return (
    <TotalWrapper>
      <StyledTopbar>
        <StyledTitle>Business Directory</StyledTitle>
      </StyledTopbar>
      <MainWrapper>
        <StyledButton onClick={() => setCollapsed(!collapsed)}>
          <StyledText $colour={Colours.white}>Add New Business</StyledText>
        </StyledButton>
        {collapsed && <CreateBusiness />}
        {businessPairs.map((pair) => {
          return pair;
        })}
      </MainWrapper>
    </TotalWrapper>
  );
};

const BusinessWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  overflow: hidden;
`;

const StyledButton = styled.div`
  height: 20px;
  background-color: ${Colours.darkBlue2};
  display: flex;
  align-items: center;
  padding: 16px;
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 16px;
  padding-top: 100px;
  padding-bottom: 24px;
`;
