import { useEffect, useState } from "react";
import styled from "styled-components";
import { Colours } from "../../components/Colours";
import { API } from "../../utils/API";
import { toast } from "react-toastify";
import { BackIcon } from "../../components/icons/BackIcon";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../hooks/useQuery";
import { StyledTopbar, TotalWrapper } from "../../components/Containers";
import { StyledTitle } from "../../components/Text";
import { UploadIcon } from "../../components/icons/UploadIcon";
import { StyledFileInput } from "../../components/Inputs";

export const EditBusiness = () => {
  const query = useQuery();
  const id = query.get("id");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState<File | string>();
  const [url, setUrl] = useState<string>();
  const [email, setEmail] = useState<string>();
  const navigate = useNavigate();

  const refresh = (id: string) => {
    API.getBusiness(id).then((business) => {
      setTitle(business.title);
      setDescription(business.description);
      setImage(business.image);
      setUrl(business.url);
      setEmail(business.email);
    });
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    refresh(id);
  }, [id]);

  const onSubmit = async () => {
    if (!id || !title || !description || !image) {
      return;
    }
    let imageUrl: string = "";
    if (!(typeof image === "string")) {
      imageUrl = await API.uploadBusinessFile(image);
    } else {
      imageUrl = image;
    }

    await API.updateBusiness({
      id,
      title,
      description,
      image: imageUrl,
      email,
      url,
    });

    refresh(id);
  };
  return (
    <TotalWrapper>
      <StyledTopbar>
        <div
          onClick={() => {
            navigate("/");
          }}
        >
          <BackIcon />
        </div>
        <StyledTitle>Edit Business</StyledTitle>
      </StyledTopbar>
      <MainWrapper>
        Title
        <StyledInput
          placeholder="Title"
          value={title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setTitle(e.target.value)
          }
        />
        {image && (
          <StyledImage
            src={typeof image === "string" ? image : URL.createObjectURL(image)}
          />
        )}
        <BlueBox>
          Upload Image
          <UploadIcon />
          <StyledFileInput
            type="file"
            accept="image/*"
            multiple
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const files = e.target.files;
              if (files) {
                const fileArray = Array.from(files);
                setImage(fileArray[0]);
              }
            }}
          />
        </BlueBox>
        Description
        <StyledTextArea
          placeholder="Description"
          value={description}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setDescription(e.target.value)
          }
        />
        URL
        <StyledInput
          placeholder="URL (optional)"
          value={url}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setUrl(e.target.value)
          }
        />
        Email
        <StyledInput
          placeholder="Email (optional)"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
        />
        <DarkButton
          onClick={() => {
            toast.promise(onSubmit(), {
              pending: "Editing post...",
              success: "Post edited!",
              error: "Failed to edit post",
            });
          }}
        >
          Submit
        </DarkButton>
      </MainWrapper>
    </TotalWrapper>
  );
};

const BlueBox = styled.div`
  width: 100%;
  height: 100px;
  background-color: ${Colours.lightBlue4};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  gap: 16px;
  position: relative;
`;

const StyledImage = styled.img`
  width: 50%;
  aspect-ratio: 1.1;
  object-fit: cover;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
`;

const DarkButton = styled.button`
  padding: 12px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${Colours.darkBlue2};
  color: white;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
`;

const StyledTextArea = styled.textarea`
  padding: 16px;
  border: 1px solid ${Colours.darkBlue3};
  border-radius: 8px;
  background-color: "white";
  height: 200px;
  font-size: 16px;
`;

const StyledInput = styled.input`
  padding: 16px;
  border: 1px solid ${Colours.darkBlue3};
  border-radius: 8px;
  background-color: "white";
  font-size: 16px;
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 16px;
  padding-top: 100px;
  padding-bottom: 24px;
`;
