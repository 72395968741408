import React from "react";
import type { UploadProps } from "antd";
import { Upload } from "antd";
import { UploadIcon } from "../icons/UploadIcon";
import styled from "styled-components";
import { toast } from "react-toastify";

interface IDragAndDropImage {
  //** max file size in MB. */
  maxFileSize?: number;
  name: string;
  onChange: (args: any) => void;
  accept?: string;
}

const { Dragger } = Upload;

export const FileDragAndDrop: React.FC<IDragAndDropImage> = ({
  maxFileSize = 10,
  name = "file",
  onChange,
  accept = "image/*",
}) => {
  const props: UploadProps = {
    name,
    multiple: true,
    showUploadList: false,
    onChange(info) {
      onChange(info.fileList);
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    beforeUpload(file) {
      const fileSize = file.size / 1024 / 1024 < maxFileSize; // Check if file size in MB
      if (!fileSize) {
        toast.error("Image must be smaller than 2MB!");
        return false;
      }
      return fileSize;
    },
  };

  return (
    <UploadWrapper>
      <UploadCard>
        <Dragger {...props} accept={accept}>
          <UploadIcon />
          <p className="ant-upload-text">Click or drag image to upload</p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Max file size : {maxFileSize}{" "}
            MB
          </p>
        </Dragger>
      </UploadCard>
    </UploadWrapper>
  );
};

const UploadWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UploadCard = styled.div`
  width: 90%;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
`;
