import { useEffect, useState } from "react";
import { API } from "../../utils/API";

type Props = {
  type: "post" | "user";
  id: string;
};

export const Reasons = ({ type, id }: Props) => {
  const [reasons, setReasons] = useState<string[]>([]);

  useEffect(() => {
    API.getReports(type, id).then((res) => {
      setReasons(res.map((r: any) => r.reason));
    });
  }, [id, type]);

  return (
    <div>
      {reasons.map((reason, i) => (
        <p key={i}>{reason}</p>
      ))}
    </div>
  );
};
