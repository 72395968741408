import { useLocation, Link } from "react-router-dom";
import { Colours } from "../components/Colours";
import { CreatePost } from "../components/icons/CreatePostIcon";
import styled from "styled-components";
import { PostsIcon } from "../components/icons/PostsIcons";
import { HomeIcon } from "../components/icons/HomeIcons";
import { Profile } from "../components/icons/ProfileIcon";
import { MailIcon } from "../components/icons/MailIcon";
import { BenefitsIcon } from "../components/icons/BenefitsIcon";
import { DeleteCircleIcon } from "../components/icons/DeleteCircleIcon";

export const BottomBar = () => {
  const location = useLocation();

  const paths = [
    "/",
    "/posts",
    "/list",
    "/users",
    "/messages",
    "/directory",
    "/reports",
  ];

  if (!paths.includes(location.pathname)) {
    return null;
  }

  return (
    <StyledBottomBar>
      <Link to={"/"}>
        <HomeIcon
          color={location.pathname === "/" ? Colours.lightBlue1 : Colours.black}
          size={28}
        />
      </Link>
      <Link to={"/posts"}>
        <PostsIcon
          color={
            location.pathname === "/posts" ? Colours.lightBlue1 : Colours.black
          }
          size={28}
        />
      </Link>
      <Link to={"users"}>
        <Profile
          color={
            location.pathname === "/users" ? Colours.lightBlue1 : Colours.black
          }
          size={28}
        />
      </Link>
      <Link to={"list"}>
        <CreatePost
          color={
            location.pathname === "/list" ? Colours.lightBlue1 : Colours.black
          }
          size={28}
        />
      </Link>
      <Link to={"messages"}>
        <MailIcon
          color={
            location.pathname === "/messages"
              ? Colours.lightBlue1
              : Colours.black
          }
          size={28}
        />
      </Link>
      <Link to={"reports"}>
        <DeleteCircleIcon
          color={
            location.pathname === "/reports"
              ? Colours.lightBlue1
              : Colours.black
          }
          size={28}
        />
      </Link>
      <Link to={"directory"}>
        <BenefitsIcon
          color={
            location.pathname === "/directory"
              ? Colours.lightBlue1
              : Colours.black
          }
          size={28}
        />
      </Link>
    </StyledBottomBar>
  );
};

const StyledBottomBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid #bdbdbd;
`;
