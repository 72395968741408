import { useEffect, useRef, useState } from "react";
import { API } from "../../utils/API";
import { Post, PostStatus } from "../../utils/types";
import { DisplayPost } from "../../components/posts/DisplayPost";
import styled from "styled-components";
import { StyledText, StyledTitle } from "../../components/Text";
import { Select } from "antd";
import Search from "antd/es/input/Search";

export const Posts = () => {
  const [posts, setPosts] = useState<Post[] | null>(null);
  const [status, setStatus] = useState<PostStatus | "All">("All");
  const [search, setSearch] = useState("");
  const loading = useRef<boolean | "END">(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    loading.current = true;
    const statusObj = status === "All" ? {} : { status };
    const searchObj = search ? { search } : {};
    API.getPosts({ ...statusObj, ...searchObj }).then((newPosts) => {
      setPosts(newPosts.posts);
      loading.current = false;
    });
  }, [status, search]);

  useEffect(() => {
    const handleScroll = async () => {
      const totalScrollHeight = document.documentElement.scrollHeight;
      const currentScrollPosition = window.scrollY + window.innerHeight;

      if (currentScrollPosition >= totalScrollHeight - 400) {
        if (!loading.current) {
          loading.current = true;
          const cursor = posts?.[posts.length - 1].id;
          const statusObj = status === "All" ? {} : { status };
          const searchObj = search ? { search } : {};
          const { posts: newPosts } = await API.getPosts({
            cursor,
            ...statusObj,
            ...searchObj,
          });
          if (newPosts.length === 0) {
            loading.current = "END";
          }
          const ids = posts?.map((p) => p.id) || [];
          const filteredPosts = newPosts.filter((p) => !ids.includes(p.id));
          setPosts([...(posts || []), ...filteredPosts]);
          loading.current = false;
        }
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [posts, status, search]);

  return (
    <StyledBackground>
      <div style={{ width: "90%" }}>
        <StyledTitle $fontsize={36}>Admin / Posts</StyledTitle>
      </div>
      <div style={{ width: "90%" }}>
        <Search
          placeholder="input search text"
          onSearch={(s) => {
            setSearch(s);
          }}
          style={{
            width: "100%",
            maxWidth: "500px",
          }}
        />
      </div>
      <div style={{ width: "90%" }}>
        <StyledText $fontsize={18}>Post status: </StyledText>
        <Select
          defaultValue={status}
          style={{
            width: 120,
          }}
          onChange={(value) => setStatus(value as PostStatus | "All")}
          options={[
            {
              label: "All",
              value: "All",
            },
            {
              label: "Approved",
              value: PostStatus.Approved,
            },
            {
              label: "Pending",
              value: PostStatus.Pending,
            },
            {
              label: "Rejected",
              value: PostStatus.Rejected,
            },
            {
              label: "Paused",
              value: PostStatus.Paused,
            },
            {
              label: "Fulfilled",
              value: PostStatus.Fulfilled,
            },
          ]}
        />
      </div>
      {posts && posts.map((post) => <DisplayPost key={post.id} post={post} />)}
    </StyledBackground>
  );
};

const StyledBackground = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
`;
