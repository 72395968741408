import { useEffect, useState } from "react";
import type { Layout, ResponsiveProps } from "react-grid-layout";
import { IImageReOrder } from "./ImageReOrder";

interface CustomResponsiveProps extends ResponsiveProps {
  lg: number;
  md: number;
  sm: number;
  xs: number;
}

// images: (File | string)[];
// onChange: (args: any) => void;

export const useImageReOrder = ({ images, onChange }: IImageReOrder) => {
  const [layout, setLayout] = useState<Layout[]>([]);
  const [reOrderImages, setReOrderImages] = useState<(File | string)[]>([]);
  const [openImageEditor, setOpenImageEditor] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<number>(0);
  const [editedImage, setEditedImage] = useState<File | null>(null);
  const [cols, setCols] = useState<number | CustomResponsiveProps>({
    lg: 8,
    md: 5,
    sm: 3,
    xs: 2,
  });

  const generateLayout = (images: (File | string)[]) => {
    //** converting iamges index to grid layout */
    const newLayout: Layout[] = [];
    const cols = 3;
    for (let i = 0; i < images.length; i++) {
      const col = i % cols;
      const row = Math.floor(i / cols);
      newLayout.push({ i: i.toString(), x: col, y: row, w: 1, h: 1 });
    }
    setLayout(newLayout);
  };

  const gridLayoutToIndex = (layout: Layout[]) => {
    //** Converting grid layout to image index */
    const maxY = layout.reduce((max, item) => Math.max(max, item.y), 0);
    const newImageOrder: (File | string)[] = [];
    for (let i = 0; i <= maxY; i++) {
      const itemsInRow = layout.filter((item) => item.y === i);
      itemsInRow.sort((a, b) => a.x - b.x);
      itemsInRow.forEach((item) => {
        newImageOrder.push(reOrderImages[Number(item?.i)]);
      });
    }
    onChange(newImageOrder);
  };

  const onLayoutChange = (newLayout: Layout[]) => {
    setLayout(newLayout);
    gridLayoutToIndex(newLayout);
  };

  const handleImageEditor = (imageId: number) => {
    setOpenImageEditor(true);
    setSelectedImage(imageId);
  };

  const handleResize = () => {
    const width = window.innerWidth;
    let newCols: number | CustomResponsiveProps = 3;
    if (width <= 768) {
      newCols = 4;
    } else if (width <= 996) {
      newCols = 5;
    }
    setCols(newCols);
  };

  const handleOk = () => {
    if (editedImage) {
      let images = reOrderImages;
      images[selectedImage] = editedImage;
      setReOrderImages(images);
      gridLayoutToIndex(layout);
    }
    setOpenImageEditor(false);
  };

  const deleteImage = (index: number) => {
    let images = reOrderImages;
    images.splice(index, 1);
    setReOrderImages(images);
    generateLayout(images);
  };

  useEffect(() => {
    generateLayout(images);
  }, [images]);

  return {
    layout,
    reOrderImages,
    setReOrderImages,
    openImageEditor,
    setOpenImageEditor,
    selectedImage,
    setSelectedImage,
    editedImage,
    setEditedImage,
    cols,
    generateLayout,
    gridLayoutToIndex,
    onLayoutChange,
    handleImageEditor,
    handleResize,
    handleOk,
    deleteImage,
  };
};
