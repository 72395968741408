import { IconProps } from "./iconProps";
import { Colours } from "../Colours";

export const PinIcon = ({ size, color }: IconProps) => {
  return (
    <svg width={size || "16"} height="16" viewBox="0 0 12 16" fill="none">
      <path
        d="M5.99964 9.99967L5.99964 14.6663M3.33297 4.87176V6.29217C3.33297 6.43085 3.33297 6.50019 3.31936 6.56652C3.30728 6.62536 3.28731 6.6823 3.25998 6.73579C3.22918 6.79608 3.18586 6.85023 3.09923 6.95852L2.05271 8.26667C1.60888 8.82145 1.38697 9.09884 1.38672 9.33229C1.3865 9.53532 1.47881 9.72738 1.63748 9.85404C1.81994 9.99967 2.17517 9.99967 2.88564 9.99967H9.11363C9.8241 9.99967 10.1793 9.99967 10.3618 9.85404C10.5205 9.72738 10.6128 9.53532 10.6126 9.33229C10.6123 9.09884 10.3904 8.82145 9.94656 8.26667L8.90004 6.95852C8.81341 6.85023 8.77009 6.79608 8.73929 6.73579C8.71196 6.6823 8.69199 6.62536 8.67991 6.56652C8.6663 6.50019 8.6663 6.43085 8.6663 6.29217V4.87176C8.6663 4.79501 8.6663 4.75664 8.67064 4.71879C8.67449 4.68517 8.6809 4.6519 8.68981 4.61925C8.69984 4.5825 8.71409 4.54687 8.74259 4.47561L9.41451 2.79582C9.61053 2.30577 9.70854 2.06074 9.66767 1.86404C9.63193 1.69203 9.52973 1.54108 9.3833 1.44401C9.21585 1.33301 8.95194 1.33301 8.42413 1.33301H3.57514C3.04733 1.33301 2.78342 1.33301 2.61597 1.44401C2.46954 1.54108 2.36734 1.69203 2.3316 1.86404C2.29073 2.06074 2.38874 2.30577 2.58476 2.79583L3.25668 4.47561C3.28518 4.54687 3.29943 4.5825 3.30946 4.61925C3.31837 4.6519 3.32478 4.68517 3.32863 4.71879C3.33297 4.75664 3.33297 4.79501 3.33297 4.87176Z"
        stroke={color || Colours.red}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
