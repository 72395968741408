import { Colours } from "../Colours";
import { IconProps } from "./iconProps";

export const BenefitsIcon = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size || "28"}
      height={size || "28"}
      viewBox="0 0 21 28"
      fill="none"
    >
      <path
        d="M10.5 20.25C15.6777 20.25 19.875 16.0527 19.875 10.875C19.875 5.69733 15.6777 1.5 10.5 1.5C5.32233 1.5 1.125 5.69733 1.125 10.875C1.125 16.0527 5.32233 20.25 10.5 20.25Z"
        fill={color === Colours.black ? "transparent" : color || "#3585BD"}
      />
      <path
        d="M4.25 17.8628V26.5L10.1286 24.1486C10.2658 24.0937 10.3344 24.0662 10.4052 24.0554C10.468 24.0457 10.532 24.0457 10.5948 24.0554C10.6656 24.0662 10.7342 24.0937 10.8714 24.1486L16.75 26.5V17.8628M19.875 10.875C19.875 16.0527 15.6777 20.25 10.5 20.25C5.32233 20.25 1.125 16.0527 1.125 10.875C1.125 5.69733 5.32233 1.5 10.5 1.5C15.6777 1.5 19.875 5.69733 19.875 10.875Z"
        stroke={color || "#3585BD"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
