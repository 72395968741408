import { useState } from "react";
import { Auth } from "../utils/auth";
import styled from "styled-components";
import { Colours } from "../components/Colours";
import { DarkButton, StyledInput } from "../components/Inputs";
import { API } from "../utils/API";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const AuthPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [codeRequested, setCodeRequested] = useState(false);
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const auth = Auth.instance;

  const onSubmit = async () => {
    if (codeRequested) {
      try {
        const { token } = await API.login(email, password, code);
        auth.setToken(token);
        navigate("/");
      } catch {
        toast.error("Invalid");
      }
      return;
    } else {
      try {
        await API.requestCode(email, password);
        setCodeRequested(true);
      } catch {
        toast.error("Invalid");
      }
    }
  };

  return (
    <StyledMainWrapper>
      <StyledInput
        type="text"
        value={email}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setEmail(e.target.value)
        }
        placeholder="Email"
      />
      <StyledInput
        type="password"
        value={password}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setPassword(e.target.value)
        }
        placeholder="Password"
      />
      {codeRequested && (
        <StyledInput
          type="text"
          value={code}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setCode(e.target.value)
          }
          placeholder="Enter code"
        />
      )}
      {codeRequested ? (
        <DarkButton onClick={onSubmit}>Login</DarkButton>
      ) : (
        <DarkButton onClick={onSubmit}>Request code</DarkButton>
      )}
    </StyledMainWrapper>
  );
};

const StyledMainWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${Colours.beige3};
  padding-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`;
