export class Auth {
  public static readonly instance = Auth.create();

  public get authHeader(): { Authorization: string } {
    return { Authorization: `Bearer ${this.getToken()}` };
  }

  public setToken(token: string): void {
    localStorage.setItem("token", token);
  }

  public getToken(): string | null {
    const token = localStorage.getItem("token");
    return token;
  }

  public clearToken(): void {
    localStorage.removeItem("token");
  }

  private static create(): Auth {
    return new Auth();
  }
}
