import { useRef, useState } from "react";
import { Colours } from "../Colours";
import { DeleteCircleIcon } from "../icons/DeleteCircleIcon";
import { LoadingImage } from "./LoadingImage";
import styled from "styled-components";

export const DisplayImages = ({
  images,
  id,
  onDelete,
  width,
  aspectRatio,
}: {
  images: string[];
  id: string;
  onDelete?: (i: number) => void;
  width: number;
  aspectRatio: number;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [index, setIndex] = useState<number>(0);
  const counter = useRef(0);

  const imageWidth = width;
  const imageHeight = imageWidth * aspectRatio;

  return (
    <ImagesWrapper>
      <HorizontalScroll
        style={{ width: imageWidth, height: imageHeight }}
        ref={ref}
        onTouchEnd={() => {
          counter.current += 1;
          const count = counter.current;
          setTimeout(() => {
            if (count === counter.current && ref.current) {
              const scroll = ref.current.scrollLeft;
              const newIndex = Math.min(
                Math.max(Math.round(scroll / imageWidth), 0),
                images.length - 1,
              );
              setIndex(newIndex);
              ref.current.scrollTo({
                left: imageWidth * newIndex,
                behavior: "smooth",
              });
            }
          }, 100);
        }}
      >
        <ImageWrapper>
          {images.map((_, i) => (
            <RelativeWrapper key={`${id}-${i}`}>
              <LoadingImage
                uri={images[i]}
                width={imageWidth}
                height={imageHeight}
              />
              {onDelete && (
                <div
                  onClick={() => {
                    if (i === images.length - 1) {
                      setIndex(i - 1);
                    }
                    onDelete(i);
                  }}
                  style={{ position: "absolute", top: 5, left: 5 }}
                >
                  <DeleteCircleIcon size={24} color={Colours.red} />
                </div>
              )}
            </RelativeWrapper>
          ))}
        </ImageWrapper>
      </HorizontalScroll>
      {images.length > 1 && (
        <CircleWrapper>
          {images.map((_, i) => (
            <StyledCircle
              key={`circle-${id}${i}`}
              style={{ opacity: i === index ? 0.8 : 0.4 }}
            />
          ))}
        </CircleWrapper>
      )}
    </ImagesWrapper>
  );
};

const RelativeWrapper = styled.div`
  position: relative;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const HorizontalScroll = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  overscroll-behavior-x: none;
  scroll-snap-type: x mandatory;
`;

const ImagesWrapper = styled.div`
  position: relative;
`;

const CircleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 0;
`;

const StyledCircle = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 3px;
  background-color: #fff;
  margin: 5px;
`;
