import styled from "styled-components";
import { UserSnapshot } from "../../utils/types";
import { Avatar } from "../Post/Avatar";
import { Profile } from "../../components/icons/ProfileIcon";
import { StyledText } from "../../components/Text";
import { useNavigate } from "react-router-dom";
import { getScreenDimensions } from "../../hooks/useScreenDimensions";

export const UserBar = ({ user }: { user: UserSnapshot }) => {
  const navigator = useNavigate();
  const { width } = getScreenDimensions();
  return (
    <Row
      onClick={() => {
        navigator(`/user?id=${user.id}`);
      }}
    >
      {user.avatar ? (
        <Avatar size={width * 0.2} image={user.avatar} />
      ) : (
        <Profile size={width * 0.2} />
      )}
      <ColumnView>
        <StyledText
          $fontsize={18}
        >{`${user.firstName} ${user.lastName}`}</StyledText>
        <StyledText
          style={{ opacity: 0.5 }}
          $fontsize={14}
        >{`ShortID: ${user.shortId}`}</StyledText>
      </ColumnView>
    </Row>
  );
};

const ColumnView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  gap: 10px;
`;
