import GridLayout from "react-grid-layout";
import styled from "styled-components";
import { useEffect } from "react";
import { getScreenDimensions } from "../../hooks/useScreenDimensions";
import { LoadingImage } from "../posts/LoadingImage";
import { EditIcon } from "../icons/EditIcon";
import { ImageEditor } from "./imageEditor";
import { Modal } from "antd";
import { useImageReOrder } from "./useImageReOrder";
import { DeleteCircleIcon } from "../icons/DeleteCircleIcon";
export interface IImageReOrder {
  images: (File | string)[];
  onChange: (args: any) => void;
}

export const ImageReOrder: React.FC<IImageReOrder> = ({ images, onChange }) => {
  const { width } = getScreenDimensions();
  const {
    layout,
    reOrderImages,
    setReOrderImages,
    openImageEditor,
    setOpenImageEditor,
    selectedImage,
    setSelectedImage,
    setEditedImage,
    cols,
    generateLayout,
    onLayoutChange,
    handleImageEditor,
    handleResize,
    handleOk,
    deleteImage,
  } = useImageReOrder({ images, onChange });

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    //** initial layout as per image index */
    generateLayout(images);
    setReOrderImages(images);
    return () => {
      window.removeEventListener("resize", handleResize);
      setOpenImageEditor(false);
      setSelectedImage(0);
    };
  }, []);

  return (
    <>
      <GridLayout
        className="layout"
        layout={layout}
        cols={cols as number}
        rowHeight={100}
        width={width}
        onLayoutChange={onLayoutChange}
        isResizable={false}
        preventCollision={false}
        draggableCancel=".image-editor"
      >
        {/* Place your grid items here */}
        {layout.map((item) => (
          <StyledImageCard key={item?.i}>
            <EditWrapper
              className="image-editor"
              onClick={() => {
                handleImageEditor(Number(item?.i));
              }}
              style={{
                top: 0,
                right: 0,
                width: "15px",
                height: "15px",
                textAlign: "center",
              }}
            >
              <EditIcon size={12} color="white" />
            </EditWrapper>
            <DeleteWrapper
              className="image-editor"
              onClick={() => {
                deleteImage(Number(item?.i));
              }}
            >
              <DeleteCircleIcon size={22} color="red" />
            </DeleteWrapper>
            <LoadingImage
              uri={
                reOrderImages.map((image) =>
                  typeof image === "string"
                    ? image
                    : URL.createObjectURL(image),
                )[Number(item?.i)]
              }
            />
          </StyledImageCard>
        ))}
      </GridLayout>
      {openImageEditor && (
        <>
          <Modal
            title="Image Editor"
            open={openImageEditor}
            onOk={handleOk}
            onCancel={() => setOpenImageEditor(false)}
            maskClosable={false}
            width={width * 0.9}
            style={{ minHeight: "40vh" }}
            classNames={{ footer: "display: none" }}
          >
            <ImageEditor
              image={
                reOrderImages.map((image) =>
                  typeof image === "string"
                    ? image
                    : URL.createObjectURL(image),
                )[selectedImage]
              }
              onChange={(editedImage: File | null) => {
                setEditedImage(editedImage);
              }}
            />
          </Modal>
        </>
      )}
    </>
  );
};

const EditWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeleteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const StyledImageCard = styled.div`
  border: 1px solid grey;
  height: 50px;
  position: relative;
  overflow: hidden;
`;
