import { Colours } from "../Colours";
import { IconProps } from "./iconProps";

export const MailIcon = ({ color, size }: IconProps) => {
  return (
    <svg
      width={size || "18px"}
      height={size ? (size * 14) / 18 : "14"}
      viewBox="0 0 18 14"
      fill="none"
    >
      <path
        d="M1.5 3.25L7.62369 7.53658C8.11957 7.8837 8.36751 8.05726 8.6372 8.12448C8.87542 8.18386 9.12458 8.18386 9.3628 8.12448C9.63249 8.05726 9.88043 7.8837 10.3763 7.53658L16.5 3.25M5.1 13H12.9C14.1601 13 14.7902 13 15.2715 12.7548C15.6948 12.539 16.039 12.1948 16.2548 11.7715C16.5 11.2902 16.5 10.6601 16.5 9.4V4.6C16.5 3.33988 16.5 2.70982 16.2548 2.22852C16.039 1.80516 15.6948 1.46095 15.2715 1.24524C14.7902 1 14.1601 1 12.9 1H5.1C3.83988 1 3.20982 1 2.72852 1.24524C2.30516 1.46095 1.96095 1.80516 1.74524 2.22852C1.5 2.70982 1.5 3.33988 1.5 4.6V9.4C1.5 10.6601 1.5 11.2902 1.74524 11.7715C1.96095 12.1948 2.30516 12.539 2.72852 12.7548C3.20982 13 3.83988 13 5.1 13Z"
        stroke={color || Colours.darkBlue1}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
