import styled from "styled-components";
import { Post } from "../../utils/types";
import { DisplayImages } from "./SwipeableImages";
import { StyledText, StyledTitle } from "../Text";
import { useNavigate } from "react-router-dom";
import { getScreenDimensions } from "../../hooks/useScreenDimensions";
import { PostCategory } from "../../utils/Categories";
import { CategoryBox } from "./CategoryBox";

export const DisplayPost = ({ post }: { post: Post }) => {
  const navigate = useNavigate();
  const { width } = getScreenDimensions();

  return (
    <TotalWrapper
      onClick={() => {
        navigate(`/post?id=${post.id}`);
      }}
    >
      <ImagesWrapper>
        <DisplayImages
          images={post.images}
          id={post.id}
          width={width}
          aspectRatio={0.65}
        />
      </ImagesWrapper>
      <TextWrapper>
        <RowView>
          <ColumnView style={{ flex: 1 }}>
            <StyledTitle
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              $fontsize={20}
            >
              {post.title}
            </StyledTitle>
            <InfoWrapper>
              <CategoryBox category={post.category} />
              <StyledText $fontsize={16}>{post.approved}</StyledText>
            </InfoWrapper>
          </ColumnView>
          <ColumnView style={{ alignItems: "flex-end" }}>
            <StyledText $fontsize={18}>{`£${post.price / 100}`}</StyledText>
            <StyledText $fontsize={18}>
              {post.category === PostCategory.Room_To_Rent ||
              post.category === PostCategory.Property_For_Rent
                ? "/ month"
                : ""}
            </StyledText>
          </ColumnView>
        </RowView>
      </TextWrapper>
    </TotalWrapper>
  );
};

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 10px;
`;

const ImagesWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

const ColumnView = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  gap: 6px;
`;

const RowView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
`;

const TotalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
