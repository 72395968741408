import React, { useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import styled from "styled-components";

interface IImageEditor {
  image: string;
  onChange: (args: File | null) => void;
}

export const ImageEditor: React.FC<IImageEditor> = ({ image, onChange }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(1);

  const onCropComplete = async (croppedArea: any, croppedAreaPixel: any) => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixel,
        rotation,
      );
      onChange(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <ImageEditorWrapper>
        <Cropper
          image={image}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={4 / 3}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </ImageEditorWrapper>
    </div>
  );
};

const ImageEditorWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  background: #333;
`;
