import { useState } from "react";
import styled from "styled-components";

export const LoadingImage = ({
  uri,
  width,
  height,
}: {
  uri: string;
  width?: number;
  height?: number;
}) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div style={{ width: width || "100%", height: height || "100%" }}>
      {!loaded && <Overlay>{/* <ActivityIndicator /> */}</Overlay>}
      <StyledImage onLoad={() => setLoaded(true)} src={uri} />
    </div>
  );
};

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: "white";
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
