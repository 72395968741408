import { useEffect } from "react";
import { RouteManager } from "./routes/Routes";
import { BrowserRouter } from "react-router-dom";
import WebFont from "webfontloader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-grid-layout/css/styles.css";

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Quattrocento Sans", "Jost", "Radley"],
      },
    });
  }, []);
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <BrowserRouter>
        <RouteManager />
      </BrowserRouter>
    </>
  );
}

export default App;
