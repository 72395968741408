import { Button, Modal } from "antd";
import { FileDragAndDrop, ImageReOrder } from "../common";
import { useEffect, useState } from "react";
import { getScreenDimensions } from "../../hooks/useScreenDimensions";

interface IImageUploader {
  maxFileSize?: number;
  name: string;
  onChange?: (args: any) => void;
  images?: (File | string)[];
  isOpened: boolean;
  onSave: (args: any) => void;
  onCancel: () => void;
}

export const ImageUploader: React.FC<IImageUploader> = ({
  maxFileSize = 10,
  name = "file",
  onChange,
  images,
  isOpened = false,
  onSave,
  onCancel,
}) => {
  const [allImages, setAllImages] = useState<(File | string)[] | undefined>(
    images,
  );
  const [orderedImages, setOrderedImages] = useState<
    (File | string)[] | undefined
  >([]);
  const [isUpload, setIsUpload] = useState<boolean>(false);
  const { width } = getScreenDimensions();

  useEffect(() => {
    setAllImages(images);
    setOrderedImages(images);
  }, [images]);

  // file upload handler
  const handleFileUpload = (files: any) => {
    setIsUpload(false);
    let combinedImages = [...files.map((file: any) => file?.originFileObj)];
    if (allImages) {
      combinedImages = [...combinedImages, ...allImages];
    }
    setAllImages(combinedImages);
    setOrderedImages(combinedImages);
  };

  // save handler
  const handleSave = () => {
    setIsUpload(false);
    onSave(orderedImages);
    // reset images
    setAllImages([]);
    setOrderedImages([]);
  };

  // cancel handler
  const handleCancel = () => {
    setIsUpload(false);
    onCancel();
    // reset images
    if (!images?.length) {
      // if its not edit
      setAllImages([]);
    }
    setOrderedImages([]);
  };

  return (
    <>
      <Modal
        title="Images"
        open={isOpened}
        onOk={handleSave}
        onCancel={handleCancel}
        maskClosable={false}
        width={width * 0.9}
        style={{ minHeight: "40vh" }}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="addImage"
            disabled={isUpload}
            onClick={() => setIsUpload(true)}
          >
            Add image
          </Button>,
          <Button key="submit" type="primary" onClick={handleSave}>
            Submit
          </Button>,
        ]}
      >
        {allImages && allImages.length > 0 && !isUpload ? (
          //** Image ReOrder component */
          <ImageReOrder
            images={allImages}
            onChange={(images: (File | string)[]) => {
              setOrderedImages(images);
              if (onChange) {
                onChange(images);
              }
            }}
          />
        ) : (
          //** File drag and drop component */
          <FileDragAndDrop
            onChange={(imgs) => handleFileUpload(imgs)}
            name={name}
            maxFileSize={maxFileSize}
            accept={"image/*"}
          />
        )}
      </Modal>
    </>
  );
};
