import { Colours } from "../Colours";
import { IconProps } from "./iconProps";

export const BackIcon = ({ color, size }: IconProps) => {
  return (
    <svg
      width={size || "24px"}
      height={size || "24px"}
      viewBox="0 0 1024 1024"
      fill={color || Colours.black}
    >
      <path d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z" />
      <path d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z" />
    </svg>
  );
};
