import { IconProps } from "./iconProps";
import { Colours } from "../Colours";

export const EditIcon = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size || "18"}
      height={size || "18"}
      viewBox="0 0 18 16"
      fill="none"
    >
      <path
        d="M8.99998 15.1662H16.5M1.5 15.1662H2.89545C3.3031 15.1662 3.50693 15.1662 3.69874 15.1202C3.8688 15.0793 4.03138 15.012 4.1805 14.9206C4.34869 14.8175 4.49282 14.6734 4.78107 14.3852L15.25 3.9162C15.9404 3.22585 15.9404 2.10656 15.25 1.4162C14.5597 0.725848 13.4404 0.725849 12.75 1.4162L2.28105 11.8852C1.9928 12.1734 1.84867 12.3175 1.7456 12.4857C1.65422 12.6348 1.58688 12.7974 1.54605 12.9675C1.5 13.1593 1.5 13.3631 1.5 13.7708V15.1662Z"
        stroke={color || Colours.lightBlue1}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
