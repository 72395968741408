import { IconProps } from "./iconProps";
import { Colours } from "../Colours";

export const PeopleIcon = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size || "16"}
      height={size || "16"}
      viewBox="0 0 16 14"
      fill="none"
    >
      <path
        d="M14.6673 13V11.6667C14.6673 10.4241 13.8175 9.38004 12.6673 9.08401M10.334 1.19384C11.3113 1.58943 12.0007 2.54754 12.0007 3.66667C12.0007 4.78579 11.3113 5.7439 10.334 6.13949M11.334 13C11.334 11.7575 11.334 11.1362 11.131 10.6462C10.8603 9.99277 10.3412 9.47364 9.68781 9.20299C9.19775 9 8.5765 9 7.33398 9H5.33398C4.09147 9 3.47022 9 2.98016 9.20299C2.32675 9.47364 1.80762 9.99277 1.53697 10.6462C1.33398 11.1362 1.33398 11.7575 1.33398 13M9.00065 3.66667C9.00065 5.13943 7.80674 6.33333 6.33398 6.33333C4.86123 6.33333 3.66732 5.13943 3.66732 3.66667C3.66732 2.19391 4.86123 1 6.33398 1C7.80674 1 9.00065 2.19391 9.00065 3.66667Z"
        stroke={color || Colours.red}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
