import { useEffect, useRef, useState } from "react";
import { API } from "../../utils/API";
import { Status, UserSnapshot } from "../../utils/types";
import styled from "styled-components";
import { StyledText, StyledTitle } from "../../components/Text";
import { Select } from "antd";
import Search from "antd/es/input/Search";
import { UserBar } from "./userBar";

export const Users = () => {
  const [users, setUsers] = useState<UserSnapshot[] | null>(null);
  const [status, setStatus] = useState<Status | "All">("All");
  const [search, setSearch] = useState("");
  const loading = useRef<boolean | "END">(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    API.getUsers(undefined, search, status === "All" ? undefined : status).then(
      (newUsers) => {
        setUsers(newUsers.users);
      },
    );
  }, [status, search]);

  useEffect(() => {
    const handleScroll = async () => {
      const totalScrollHeight = document.documentElement.scrollHeight;
      const currentScrollPosition = window.scrollY + window.innerHeight;

      if (currentScrollPosition >= totalScrollHeight - 400) {
        if (!loading.current) {
          loading.current = true;
          const cursor = users?.[users.length - 1].id;
          const { users: newUsers } = await API.getUsers(cursor);
          if (newUsers.length === 0) {
            loading.current = "END";
          }
          const ids = users?.map((p) => p.id) || [];
          const filteredUsers = newUsers.filter((p) => !ids.includes(p.id));
          setUsers([...(users || []), ...filteredUsers]);
          loading.current = false;
        }
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [users]);

  return (
    <StyledBackground>
      <div style={{ width: "90%" }}>
        <StyledTitle $fontsize={36}>Admin / Users</StyledTitle>
      </div>
      <div style={{ width: "90%" }}>
        <Search
          placeholder="input search text"
          onSearch={(s) => {
            setSearch(s);
          }}
          style={{
            width: "100%",
            maxWidth: "500px",
          }}
        />
      </div>
      <div style={{ width: "90%" }}>
        <StyledText $fontsize={18}>User status: </StyledText>
        <Select
          defaultValue={status}
          style={{
            width: 120,
          }}
          onChange={(value) => setStatus(value as Status | "All")}
          options={[
            {
              label: "All",
              value: "All",
            },
            {
              label: "Accepted",
              value: Status.ACCEPTED,
            },
            {
              label: "Pending",
              value: Status.PENDING,
            },
            {
              label: "Onboarding",
              value: Status.ONBOARDING,
            },
          ]}
        />
      </div>

      {users && users.map((user) => <UserBar key={user.id} user={user} />)}
    </StyledBackground>
  );
};

const StyledBackground = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
`;
