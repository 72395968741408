import { IconProps } from "./iconProps";

export const PostsIcon = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size || "800px"}
      height={size || "800px"}
      viewBox="0 0 24 24"
      fill={color || "#000000"}
    >
      <path d="M16 19H3v-2h13v2zm5-10H3v2h18V9zM3 5v2h11V5H3zm14 0v2h4V5h-4zm-6 8v2h10v-2H11zm-8 0v2h5v-2H3z" />
    </svg>
  );
};
