import styled from "styled-components";
import { CategoryItems, PostCategory } from "../../utils/Categories";
import { Colours } from "../Colours";
import { StyledText } from "../Text";

type Props = {
  category: PostCategory;
};

export const CategoryBox = ({ category }: Props) => {
  const categoryItem = CategoryItems.find((c) => c.category === category) || {
    label: "Yard Sale",
  };

  return (
    <Wrapper colour={Colours.green}>
      <StyledText $colour={Colours.white}>{categoryItem.label}</StyledText>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ colour: string }>`
  border-radius: 4px;
  background-color: ${({ colour }) => colour};
  padding: 0 4px;
  flex-shrink: 1;
`;
