import { styled } from "styled-components";
import { Colours } from "../../components/Colours";
import { StyledText } from "../../components/Text";
import { useState } from "react";
import { Post, PostStatus } from "../../utils/types";
import { API } from "../../utils/API";
import { useNavigate } from "react-router-dom";

import DeleteIcon from "../../assets/icons/delete.svg";
import PauseIcon from "../../assets/icons/pause.svg";
import FulfillIcon from "../../assets/icons/fulfill.svg";

type Props = {
  post: Post;
};

export const Actions = ({ post }: Props) => {
  const navigator = useNavigate();
  const [confirmed, setConfirmed] = useState<false | string>(false);

  const onOptionConfirm = (option: string) => {
    return () => {
      if (confirmed === option) {
        setConfirmed(false);
      } else {
        setConfirmed(option);
      }
    };
  };

  const onDelete = async () => {
    if (confirmed !== "delete") return;
    await API.deletePost(post.id);
    navigator("/posts");
  };

  const onUnfulfill = async () => {
    if (confirmed !== "fulfill") return;
    await API.unfulfill(post.id);
    window.location.reload();
  };

  const onFulfill = async () => {
    if (confirmed !== "fulfill") return;
    await API.fulfillPost(post.id);
    window.location.reload();
  };

  const onToggleFreeze = async () => {
    if (confirmed !== "freeze") return;
    if (post.approved === PostStatus.Paused) {
      await API.unfreezePost(post.id);
    } else {
      await API.freezePost(post.id);
    }
    window.location.reload();
  };

  if (
    !post ||
    post.approved === PostStatus.Pending ||
    post.approved === PostStatus.Rejected
  ) {
    return (
      <RowView>
        <RowView style={{ gap: 8 }} onClick={onDelete}>
          <StyledIcon src={DeleteIcon} />
          <StyledText $fontsize={22} $colour={Colours.red}>
            Delete Post
          </StyledText>
        </RowView>
        <ConfirmCircle
          confirmed={confirmed === "delete"}
          onClick={onOptionConfirm("delete")}
        />
      </RowView>
    );
  }

  if (post.approved === PostStatus.Fulfilled) {
    return (
      <OptionsWrapper>
        <RowView>
          <RowView style={{ gap: 8 }} onClick={onDelete}>
            <StyledIcon src={DeleteIcon} />
            <StyledText $fontsize={22} $colour={Colours.red}>
              Delete Post
            </StyledText>
          </RowView>
          <ConfirmCircle
            confirmed={confirmed === "delete"}
            onClick={onOptionConfirm("delete")}
          />
        </RowView>
        <RowView>
          <RowView style={{ gap: 8 }} onClick={onUnfulfill}>
            <StyledIcon src={FulfillIcon} />
            <StyledText $fontsize={22} $colour={Colours.red}>
              Unfulfill
            </StyledText>
          </RowView>
          <ConfirmCircle
            confirmed={confirmed === "fulfill"}
            onClick={onOptionConfirm("fulfill")}
          />
        </RowView>
      </OptionsWrapper>
    );
  }

  return (
    <OptionsWrapper>
      <RowView>
        <RowView style={{ gap: 8 }} onClick={onDelete}>
          <StyledIcon src={DeleteIcon} />
          <StyledText $fontsize={22} $colour={Colours.red}>
            Delete Post
          </StyledText>
        </RowView>
        <ConfirmCircle
          confirmed={confirmed === "delete"}
          onClick={onOptionConfirm("delete")}
        />
      </RowView>
      <LightBar />
      <RowView>
        <RowView style={{ gap: 8 }} onClick={onToggleFreeze}>
          <StyledIcon src={PauseIcon} />
          <StyledText $fontsize={22} $colour={Colours.red}>
            {post.approved === PostStatus.Paused ? "Unfreeze" : "Freeze"}
          </StyledText>
        </RowView>
        <ConfirmCircle
          confirmed={confirmed === "freeze"}
          onClick={onOptionConfirm("freeze")}
        />
      </RowView>
      <LightBar />
      <RowView>
        <RowView style={{ gap: 8 }} onClick={onFulfill}>
          <StyledIcon src={FulfillIcon} />
          <StyledText $fontsize={22} $colour={Colours.red}>
            Fulfill
          </StyledText>
        </RowView>
        <ConfirmCircle
          confirmed={confirmed === "fulfill"}
          onClick={onOptionConfirm("fulfill")}
        />
      </RowView>
    </OptionsWrapper>
  );
};

const ConfirmCircle = styled.div<{ confirmed: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid ${Colours.darkBlue1};
  background-color: ${(props) =>
    props.confirmed ? Colours.darkBlue1 : Colours.white};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
`;

const RowView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LightBar = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background-color: ${Colours.darkBlue1};
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const StyledIcon = styled.img`
  width: 24px;
  height: 24px;
`;
