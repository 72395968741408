import styled from "styled-components";
import { Colours } from "./Colours";

export const StyledFileInput = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
`;

export const StyledInput = styled.input`
  padding: 16px;
  border: 1px solid ${Colours.darkBlue3};
  border-radius: 8px;
  background-color: "white";
  font-size: 16px;
`;

export const DarkButton = styled.button`
  padding: 12px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${Colours.darkBlue2};
  color: white;
  font-weight: bold;
  font-size: 16px;
`;

export const LightButton = styled.button`
  padding: 12px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px solid ${Colours.darkBlue3};
  background-color: ${Colours.beige3};
  color: white;
  font-weight: bold;
  font-size: 16px;
`;
