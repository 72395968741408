import { IconProps } from "./iconProps";

export const DeleteCircleIcon = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size || "800px"}
      height={size || "800px"}
      viewBox="-1 -1 50 50"
      stroke={color}
    >
      <path d="M24,48A24,24,0,1,1,48,24,24,24,0,0,1,24,48ZM24,1A23,23,0,1,0,47,24,23,23,0,0,0,24,1Z" />
      <path d="M18.7,29.8A0.5,0.5,0,0,1,18.34,29L29,18.34a0.5,0.5,0,0,1,.71.71L19.05,29.66A0.5,0.5,0,0,1,18.7,29.8Z" />
      <path d="M29.3,29.8A0.5,0.5,0,0,1,29,29.66L18.34,19A0.5,0.5,0,0,1,19,18.34L29.66,29A0.5,0.5,0,0,1,29.3,29.8Z" />
    </svg>
  );
};
