import styled from "styled-components";
import { StyledText, StyledTitle } from "../../components/Text";
import { useEffect, useMemo, useState } from "react";
import { Analytics } from "../../utils/types";
import { API } from "../../utils/API";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { DarkButton } from "../../components/Inputs";
import { Colours } from "../../components/Colours";
import { useNavigate } from "react-router-dom";

ChartJS.register(ArcElement, Tooltip, Legend);

export const Home = () => {
  const [analytics, setAnalytics] = useState<Analytics | null>(null);
  const navigator = useNavigate();

  useEffect(() => {
    API.getAnalytics().then((data) => {
      setAnalytics(data);
    });
  }, []);

  const data = useMemo(() => {
    if (!analytics) {
      return null;
    }
    return {
      userData: {
        labels: ["Accepted", "Onboarding", "Pending"],
        datasets: [
          {
            label: "# of Users",
            data: [
              analytics.users.accepted,
              analytics.users.onboarding,
              analytics.users.pending,
            ],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
            ],
            borderWidth: 1,
          },
        ],
      },
      postData: {
        labels: ["Fulfiled", "Live", "Pending", "Paused", "Rejected"],
        datasets: [
          {
            label: "# of Posts",
            data: [
              analytics.posts.fulfiled,
              analytics.posts.live,
              analytics.posts.pending,
              analytics.posts.paused,
              analytics.posts.rejected,
            ],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
            ],
            borderWidth: 1,
          },
        ],
      },
    };
  }, [analytics]);

  const onNotification = async () => {
    navigator("/notifications");
  };

  return (
    <StyledBackground>
      <SmallWrapper>
        <StyledTitle $fontsize={36}>Admin / Home</StyledTitle>
        {data && analytics && (
          <DataWrapper>
            <StyledText
              $fontsize={24}
            >{`Total Users: ${analytics.users.total}`}</StyledText>
            <Pie data={data.userData} />
            <StyledText
              $fontsize={24}
            >{`Total Posts: ${analytics.posts.total}`}</StyledText>
            <Pie data={data.postData} />
          </DataWrapper>
        )}
        <DarkButton onClick={onNotification}>
          <StyledText $fontsize={24} $colour={Colours.white}>
            Notification Tool
          </StyledText>
        </DarkButton>
      </SmallWrapper>
    </StyledBackground>
  );
};

const DataWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
`;

const SmallWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
`;

const StyledBackground = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
`;
