import { useEffect, useState } from "react";
import styled from "styled-components";
import { Colours } from "../../components/Colours";
import { Cascader } from "antd";
import { EditPost } from "../../utils/types";
import { API } from "../../utils/API";
import { toast } from "react-toastify";
import { BackIcon } from "../../components/icons/BackIcon";
import { useNavigate } from "react-router-dom";
import { DisplayImages } from "../../components/posts/SwipeableImages";
import { getScreenDimensions } from "../../hooks/useScreenDimensions";
import { useQuery } from "../../hooks/useQuery";
import { StyledTopbar, TotalWrapper } from "../../components/Containers";
import { CategoryItems, PostCategory } from "../../utils/Categories";
import { ImageUploader } from "../../components/posts/ImageUploader";

const options: { value: PostCategory; label: string }[] = CategoryItems.map(
  (item) => {
    return {
      value: item.category,
      label: item.label,
    };
  },
);

export const Edit = () => {
  const query = useQuery();
  const [imageEditor, setImageEditor] = useState<boolean>(false);
  const id = query.get("id");
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState<(File | string)[]>([]);
  const [category, setCategory] = useState<PostCategory | null>(null);
  const navigate = useNavigate();
  const { width } = getScreenDimensions();

  const refresh = (id: string) => {
    API.getPost(id).then(({ post }) => {
      setTitle(post.title);
      setPrice((post.price / 100).toString());
      setLocation(post.location);
      setDescription(post.description);
      setCategory(post.category);
      setImages(post.images);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!id) {
      return;
    }
    refresh(id);
  }, [id]);

  const onPriceInput = (text: string) => {
    if (text.length === 0) {
      setPrice("");
      return;
    }
    try {
      const num = Number(text);
      if (num || num === 0) {
        setPrice(text);
      }
    } catch {}
  };

  const onSubmit = async () => {
    if (
      !id ||
      !title ||
      !price ||
      !location ||
      !description ||
      !images ||
      !category
    ) {
      return;
    }
    const imageUrlPromises = images.map((image) => {
      if (typeof image === "string") {
        return image;
      }
      return API.uploadProductFile(image);
    });
    const imageUrls = await Promise.all(imageUrlPromises);
    const newPost: EditPost = {
      id,
      title,
      price: parseFloat(price),
      location,
      description,
      images: imageUrls,
      category,
    };
    await API.editPost(newPost);
    refresh(id);
  };
  return (
    <TotalWrapper>
      <StyledTopbar>
        <div
          onClick={() => {
            navigate("/");
          }}
        >
          <BackIcon />
        </div>
        Update Post
      </StyledTopbar>
      <MainWrapper>
        Title
        <StyledInput
          placeholder="Title"
          value={title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setTitle(e.target.value)
          }
        />
        {images.length > 0 && (
          <ImageWrapper>
            <DisplayImages
              images={images.map((image) =>
                typeof image === "string" ? image : URL.createObjectURL(image),
              )}
              id={""}
              onDelete={(index) => {
                setImages(images.splice(index, 1));
              }}
              width={width * 0.9}
              aspectRatio={0.7}
            />
          </ImageWrapper>
        )}
        <DarkButton onClick={() => setImageEditor(true)}>
          Update images
        </DarkButton>
        <ImageUploader
          name={"file"}
          maxFileSize={10}
          isOpened={imageEditor}
          onSave={(imgs) => {
            setImages(imgs);
            setImageEditor(false); // closing imageEditor
          }}
          onCancel={() => setImageEditor(false)}
          images={images}
        />
        Category
        <StyledCascader
          options={options}
          placeholder="Select a Category"
          onChange={(e: (string | number | null)[]) => {
            setCategory(e?.[0] as PostCategory);
          }}
          value={category ? [category] : undefined}
          variant={"borderless"}
        />
        Price £
        <StyledInput
          placeholder="Price"
          value={price}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onPriceInput(e.target.value)
          }
        />
        Location
        <StyledInput
          placeholder="Price"
          value={location}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setLocation(e.target.value)
          }
        />
        Description
        <StyledTextArea
          placeholder="Description"
          value={description}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setDescription(e.target.value)
          }
        />
        <DarkButton
          onClick={() => {
            toast.promise(onSubmit(), {
              pending: "Editing post...",
              success: "Post edited!",
              error: "Failed to edit post",
            });
          }}
        >
          Submit
        </DarkButton>
      </MainWrapper>
    </TotalWrapper>
  );
};

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
`;

const StyledCascader = styled(Cascader)`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid ${Colours.darkBlue3};
`;

const DarkButton = styled.button`
  padding: 12px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${Colours.darkBlue2};
  color: white;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
`;

const StyledTextArea = styled.textarea`
  padding: 16px;
  border: 1px solid ${Colours.darkBlue3};
  border-radius: 8px;
  background-color: "white";
  height: 200px;
  font-size: 16px;
`;

const StyledInput = styled.input`
  padding: 16px;
  border: 1px solid ${Colours.darkBlue3};
  border-radius: 8px;
  background-color: "white";
  font-size: 16px;
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 16px;
  padding-top: 100px;
  padding-bottom: 24px;
`;
