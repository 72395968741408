import styled from "styled-components";
import { getScreenDimensions } from "../../hooks/useScreenDimensions";
import { DisplayImages } from "../../components/posts/SwipeableImages";
import { BackIcon } from "../../components/icons/BackIcon";
import { useNavigate } from "react-router-dom";
import { EditIcon } from "../../components/icons/EditIcon";

export const TopImage = ({ images, id }: { images: string[]; id: string }) => {
  const { width } = getScreenDimensions();
  const navigate = useNavigate();

  return (
    <StyledView style={{ width, height: width }}>
      <DisplayImages images={images} id={""} width={width} aspectRatio={1} />
      <PresssableCircle
        onClick={() => {
          navigate("/posts");
        }}
        style={{ top: 10, left: 10 }}
      >
        <BackIcon size={20} color="white" />
      </PresssableCircle>
      <PresssableCircle
        onClick={() => {
          navigate({ pathname: "/edit", search: `?id=${id}` });
        }}
        style={{ top: 10, right: 10 }}
      >
        <EditIcon size={20} color="white" />
      </PresssableCircle>
    </StyledView>
  );
};

export const PresssableCircle = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10px;
`;

const StyledView = styled.div`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  position: relative;
`;
